import { useEffect, useState, FC } from 'react';
import { useSelector } from 'react-redux';
import { Heading, Box } from '@chakra-ui/react';
import PageHeading from '../../components/PageHeading';
import AnimationFramer from '../../components/AnimationFramer';
import AlbumsList from '../../components/AlbumsList';
import { IAlbum, IState } from '../../types';

const Library: FC = () => {
	const STATE = useSelector((state: IState) => state);
	const { albums } = STATE.data;
	const { favorite_albums } = STATE.user;

	const [load, setLoad] = useState(true);

	const [albumList, setAlbumList] = useState<IAlbum[]>([]);

	useEffect(() => {
		if (albums && favorite_albums) {
			const albumInfoArr: IAlbum[] = [];
			favorite_albums.forEach(e => {
				const albumInfo: IAlbum | undefined = albums.find(i => i.id === e);
				if (albumInfo) {
					albumInfoArr.push(albumInfo);
				}
			});
			setAlbumList(albumInfoArr);
			setLoad(false);
		}
	}, [albums, favorite_albums]);

	return (
		<Box>
			<PageHeading title={'Library'} />
			{!load ? (
				<Box>
					<Box {...{ mb: '30px' }}>
						<AnimationFramer>
							<Heading as={'h2'} {...{ fontSize: 32, fontWeight: 'bold' }}>
								Your Albums
							</Heading>
						</AnimationFramer>
					</Box>
					<AlbumsList list={albumList} withArtists />
				</Box>
			) : null}
		</Box>
	);
};

export default Library;
