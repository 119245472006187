import CryptoJS from 'crypto-js';
import Validator from 'validator';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import { Box, Flex, Stack, Heading, Input, Button, FormControl, FormLabel, InputGroup, InputRightElement, IconButton } from '@chakra-ui/react';
import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons';
import { login } from '../../store';
import { toastSet } from '../../store';

import Api from '../../api';

interface formValues {
	email: string;
	password: string;
}

const formInitValues: formValues = {
	email: '',
	password: '',
};

const Login = () => {
	const dispatch = useDispatch();
	const [formLoad, setFormLoad] = useState(false);

	const [passwordView, setPasswordView] = useState(false);

	const formik = useFormik({
		initialValues: formInitValues,
		validate: values => {
			const errors: { [propName: string]: boolean } = {};
			if (!values.email || !Validator.isEmail(values.email)) errors.email = true;
			if (!values.password) errors.password = true;
			return errors;
		},
		onSubmit: (values, { resetForm }) => {
			console.log('🚀 ~ file: index.jsx:45 ~ Login ~ values:', values);
			handleLogin(values, resetForm);
		},
	});

	const handleLogin = (formData: formValues, resetForm: () => void) => {
		const { email, password } = formData;
		const encryptPassword = CryptoJS.AES.encrypt(password, 'FRii032d').toString();
		const payload = { email, password: encryptPassword };
		setFormLoad(true);
		Api.login(payload)
			.then(res => {
				const data = res.data;
				if (data.status === 'success') {
					const { token, user } = data.result;
					localStorage.setItem('token', token);
					localStorage.setItem('user', JSON.stringify(user));
					dispatch(login(user));
					resetForm();
				}
				setFormLoad(false);
			})
			.catch(error => {
				dispatch(toastSet({ status: 'error', title: 0, text: error.response && error.response.data.message ? error.response.data.message : error.message }));
				setFormLoad(false);
			});
	};

	return (
		<Flex minH={'100vh'} align={'center'} justify={'center'}>
			{formik ? (
				<Stack spacing={8} mx={'auto'} maxW={'lg'} py={12} px={6}>
					<Stack align={'center'}>
						<Heading fontSize={'4xl'}>Sign in</Heading>
					</Stack>
					<Box rounded={'lg'} boxShadow={'lg'} p={8}>
						<form onSubmit={formik.handleSubmit}>
							<Stack spacing={4}>
								<FormControl id="email">
									<FormLabel>Email address</FormLabel>
									<Input id={'email'} name={'email'} type={'email'} placeholder={'Enter your email'} isDisabled={formLoad} value={formik.values.email} onChange={formik.handleChange} />
								</FormControl>
								<FormControl isInvalid={!!(formik.touched && formik.touched.password && formik.errors && formik.errors.password)}>
									<FormLabel htmlFor="password">Password</FormLabel>
									<InputGroup>
										<Input id={'password'} name={'password'} type={passwordView ? 'text' : 'password'} placeholder={'Enter your password'} isDisabled={formLoad} value={formik.values.password} onChange={formik.handleChange} />
										<InputRightElement>
											<IconButton aria-label="Password view" icon={passwordView ? <ViewOffIcon /> : <ViewIcon />} onClick={() => setPasswordView(!passwordView)} />
										</InputRightElement>
									</InputGroup>
								</FormControl>
								<Stack spacing={10}>
									<Button type={'submit'} colorScheme="purple">
										Sign in
									</Button>
								</Stack>
							</Stack>
						</form>
					</Box>
				</Stack>
			) : (
				'...loading'
			)}
		</Flex>
	);
};

export default Login;
