import { openDB } from 'idb';

const dbPromise = openDB('store', 1, {
	upgrade(db) {
		db.createObjectStore('audio');
		db.createObjectStore('image');
	},
});

interface DB_props {
	type: string;
	key: string;
	val: string;
}

export async function DBget(type: DB_props['type'], key: DB_props['key']): Promise<string> {
	return (await dbPromise).get(type, key);
}
export async function DBset(type: DB_props['type'], key: DB_props['key'], val: DB_props['val']): Promise<void> {
	(await dbPromise).put(type, val, key);
}
export async function DBdel(type: DB_props['type'], key: DB_props['key']): Promise<void> {
	(await dbPromise).delete(type, key);
}
export async function DBclear(type: DB_props['type']): Promise<void> {
	(await dbPromise).clear(type);
}
export async function DBkeys(type: DB_props['type']): Promise<any[]> {
	return (await dbPromise).getAllKeys(type);
}
