import { useEffect, FC } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Image, useColorModeValue } from '@chakra-ui/react';
import { imagesAddLoad } from '../../store';
import loadImageDark from '../../assets/image/loadImageDark.gif';
import loadImageLight from '../../assets/image/loadImageLight.gif';
import { IState } from '../../types';

const ImageLoader: FC<{ imageID: string; [propName: string]: any }> = ({ imageID, ...props }) => {
	const dispatch = useDispatch();
	const loadImage = useColorModeValue(loadImageLight, loadImageDark);
	const { images } = useSelector((state: IState) => state);

	useEffect(() => {
		if (imageID && !images[imageID]) {
			dispatch(imagesAddLoad(imageID));
		}
	}, [imageID]);

	return <Image loading={'lazy'} alt={'img'} src={images[imageID] ? images[imageID] : loadImage} {...props} />;
};

export default ImageLoader;
