import { configureStore, createSlice } from '@reduxjs/toolkit';
import { IState } from '../types';

const initialState: IState = {
	initialization: true,
	authentication: false,
	user: {
		name: '',
		email: '',
		favorite_albums: [],
		favorite_artists: [],
		favorite_tracks: [],
	},
	data: {
		genres: [],
		artists: [],
		albums: [],
		tracks: [],
	},

	isOnline: true,
	toast: false,

	playlist: [],
	playlistID: false,
	playlistShow: false,
	activeTrack: false,
	playing: false,
	images: {},
	loadingImages: [],

	// isScroll: true,
};

const stateSlice = createSlice({
	name: 'state',
	initialState,
	reducers: {
		login: (state, action) => {
			const user = action.payload;
			const { name, email, favorite_albums, favorite_artists, favorite_tracks } = user;
			state.initialization = false;
			state.authentication = true;
			state.user = {
				name,
				email,
				favorite_albums,
				favorite_artists,
				favorite_tracks,
			};
		},
		logout: state => {
			state.initialization = false;
			state.authentication = false;
			state.user = {
				name: '',
				email: '',
				favorite_albums: [],
				favorite_artists: [],
				favorite_tracks: [],
			};

			localStorage.setItem('token', '');
			localStorage.setItem('user', '');
		},

		toastSet: (state, action) => {
			const data = action.payload;
			const { status, title, text } = data;

			state.toast = { status, title, text };
		},
		toastRemove: state => {
			state.toast = false;
		},
		infoSet: (state, action) => {
			const data = action.payload;
			const { genres, albums, artists, tracks } = data;
			state.data = {
				genres,
				albums,
				artists,
				tracks,
			};
		},
		imagesSet: (state, action) => {
			const data = action.payload;
			state.images = data;
		},
		imagesAdd: (state, action) => {
			const { ID, image } = action.payload;
			if (!state.images[ID]) {
				state.images[ID] = image;
				if (state.loadingImages.includes(ID)) {
					state.loadingImages = state.loadingImages.filter(e => e !== ID);
				}
			}
		},
		imagesAddLoad: (state, action) => {
			const ID = action.payload;
			if (!state.images[ID] && !state.loadingImages.includes(ID)) {
				state.loadingImages.push(ID);
			}
		},
		playlistShowToggle: (state, action) => {
			const playlistShow = action.payload;
			state.playlistShow = playlistShow !== undefined ? playlistShow : !state.playlistShow;
		},
		playlistSet: (state, action) => {
			const data = action.payload;

			if (data.playlist && data.playlist.length) {
				state.playlist = data.playlist;
			}

			if (data.playlistID) {
				state.playlistID = data.playlistID;
			}

			if ((data.playlist && data.playlist.length) || (state.playing && state.playlist.length)) {
				state.playing = true;
			}

			if (data.activeTrack) {
				state.playing = true;
				state.activeTrack = data.activeTrack;
			} else if (data.playlist && data.playlist.length) {
				state.activeTrack = data.playlist[0];
			}
		},
		playToggle: state => {
			state.playing = !state.playing;
		},
		scrollSet: (state, action) => {
			const data = action.payload;
			// if (data !== undefined) {
			//     state.isScroll = data;
			// } else {
			//     state.isScroll = !state.isScroll;
			// }
		},
		onlineSet: (state, action) => {
			const data = action.payload;
			state.isOnline = data;
		},
	},
});

export const { login, logout, toastSet, toastRemove, infoSet, imagesSet, imagesAdd, imagesAddLoad, playlistShowToggle, playlistSet, playToggle, scrollSet, onlineSet } = stateSlice.actions;

export default configureStore({
	reducer: stateSlice.reducer,
});
