import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Heading, Box } from '@chakra-ui/react';
import PageHeading from '../../components/PageHeading';
import AnimationFramer from '../../components/AnimationFramer';
import ArtistsList from '../../components/AtristsList';
import { IState, IArtist } from '../../types';

const Artists = () => {
	const STATE = useSelector((state: IState) => state);
	const { artists } = STATE.data;

	const [load, setLoad] = useState(true);

	const [artistsList, setArtistsList] = useState<IArtist[]>([]);

	useEffect(() => {
		if (artists) {
			dataLoad();
		}
	}, [artists]);

	const dataLoad = async () => {
		setArtistsList(artists);
		setLoad(false);
	};

	return (
		<Box>
			<PageHeading title={'Artists'} />
			{!load ? (
				<Box>
					<Box {...{ mb: '30px' }}>
						<AnimationFramer>
							<Heading as={'h2'} {...{ fontSize: 32, fontWeight: 'bold' }}>
								Catalog
							</Heading>
						</AnimationFramer>
					</Box>
					<ArtistsList list={artistsList} />
				</Box>
			) : null}
		</Box>
	);
};

export default Artists;
