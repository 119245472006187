import { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Flex, Heading, Box, useColorModeValue, Text, Button, Icon } from '@chakra-ui/react';
import { PlayCircleFill, PauseCircleFill } from 'react-bootstrap-icons';
import ImageLoader from '../../components/ImageLoader';
import AnimationFramer from '../../components/AnimationFramer';
import AlbumsList from '../../components/AlbumsList';
import { timeFormat } from '../../tools';
import { playToggle, playlistSet } from '../../store';
import { IState, IAlbum, IArtist, ITrack } from '../../types';
import { useWindowSize } from '../../hooks';

const ArtistsSingle = () => {
	const dispatch = useDispatch();
	const STATE = useSelector((state: IState) => state);
	const { playlistID, playing, activeTrack } = STATE;
	const { albums, artists, tracks } = STATE.data;

	const { id } = useParams();
	const [width] = useWindowSize();

	const [load, setLoad] = useState(true);

	const [currentArtist, setCurrentArtist] = useState<IArtist | false>(false);
	const [currentAlbums, setCurrentAlbums] = useState<IAlbum[]>([]);
	const [currentTracksIDs, setCurrentTracksIDs] = useState<ITrack['id'][]>([]);

	const playlistActiveItemBg = useColorModeValue('#5D528C', '#192452');
	const fontColor = useColorModeValue('#727377', '#CCD1E5');
	const bg = useColorModeValue('rgba(255, 255, 255, 0.5)', 'rgba(13, 21, 57, 0.5)');

	useEffect(() => {
		if (artists && albums && id) {
			dataLoad();
		}
	}, [artists, albums, id]);

	const dataLoad = async () => {
		const artistInfo: IArtist | undefined = artists.find((e: IArtist) => e.id === id);
		if (artistInfo && id) {
			// For server
			const albumsByArtist: IAlbum[] = albums.filter((e: IAlbum) => e.artist_IDs.includes(id)).sort((a: IAlbum, b: IAlbum) => parseInt(b.year) - parseInt(a.year));
			const tracksByArtist: ITrack['id'][] = [];
			albumsByArtist.forEach((album: IAlbum) => {
				tracksByArtist.push(...album.track_IDs);
			});
			setCurrentAlbums(albumsByArtist);
			setCurrentTracksIDs(tracksByArtist);
			setCurrentArtist(artistInfo);
		}
		setLoad(false);
	};

	const setArtistOnPlaylist = (trackID: ITrack['id']) => {
		if (activeTrack === trackID) {
			dispatch(playToggle());
		} else if (id === playlistID && activeTrack !== trackID) {
			dispatch(playlistSet({ activeTrack: trackID }));
		} else {
			dispatch(playlistSet({ playlist: currentTracksIDs, playlistID: id, activeTrack: trackID }));
		}
	};

	return (
		<Box>
			{!load && currentArtist ? (
				<Box>
					<AnimationFramer>
						<Flex {...{ flexDirection: { base: 'column', lg: 'row' }, gap: '50px', alignItems: 'center', height: { base: 'auto', lg: '320px' }, mb: '30px' }}>
							<Box {...{ position: 'relative' }}>
								<Box {...{ position: 'relative', boxShadow: 'inset 0 0 12px 1px rgba(0, 0, 0, 0.2)', borderRadius: '50%', p: '10px' }}>
									<ImageLoader imageID={currentArtist.image_ID} {...{ h: '300px', w: '300px', borderRadius: '50%', objectFit: 'cover', objectPosition: 'center' }} />
								</Box>
								<Flex {...{ position: 'absolute', textAlign: { base: 'center', lg: 'left' }, px: '10px', backdropFilter: 'blur(5px)', bg: bg, left: 0, right: 0, top: { base: '230px', lg: '200px' }, bottom: 0, flexDirection: 'column', justifyContent: 'center' }}>
									<Heading as={'h2'} {...{ display: { base: 'none', lg: 'block' }, fontSize: 18, fontWeight: 'normal' }}>
										Artist
									</Heading>
									<Heading as={'h1'} {...{ fontSize: 32, fontWeight: 'bold' }}>
										{currentArtist.name}
									</Heading>
								</Flex>
							</Box>

							<Box {...{ flex: 1, maxW: '1000px' }}>
								<Box {...{ h: '300px', w: '100%' }}>
									<Box className={'scroll_playlist'} {...{ h: '300px', overflowY: 'auto', mx: { base: '-10px', lg: 0 } }}>
										{currentTracksIDs.length
											? currentTracksIDs.map((e, i) => {
													const isActive = activeTrack === e;
													const trackInfo = tracks.find(f => f.id === e);
													if (trackInfo) {
														const albumInfo = albums.find(f => f.id === trackInfo.album_ID);
														return (
															<Flex
																key={e}
																{...{
																	width: '100%',
																	h: '60px',
																	gap: '10px',
																	px: { base: '10px', lg: 0 },
																	alignItems: 'center',
																	fontSize: 15,
																	color: isActive ? '#ffffff' : fontColor,
																	background: isActive ? playlistActiveItemBg : 'transparent',
																	borderRadius: isActive ? '4px' : '0',
																	boxShadow: isActive ? '0 3px 4px 0 rgba(0, 0, 0, 0.05)' : 'none',
																}}
															>
																<Flex onClick={() => (width <= 992 ? setArtistOnPlaylist(e) : null)} {...{ justifyContent: 'center', alignItems: 'center', w: '50px', h: '50px', position: 'relative' }}>
																	<ImageLoader imageID={trackInfo.image_ID} {...{ borderRadius: '4px', h: '40px', w: '40px', boxShadow: '0 2px 7px 0 rgba(0, 0, 0, 0.50)' }} />
																	<Button variant={'palyerButton'} onClick={() => setArtistOnPlaylist(e)} {...{ position: 'absolute', borderRadius: '50%', bg: isActive ? '#192452' : '#141C41', opacity: 0, _hover: { opacity: 1 }, _focus: { opacity: 1 } }}>
																		<Icon as={isActive && playing ? PauseCircleFill : PlayCircleFill} {...{ fontSize: 30 }} />
																	</Button>
																</Flex>
																<Flex {...{ flex: '1', columnGap: '30px', rowGap: '2px', flexDirection: { base: 'column', xl: 'row' } }}>
																	<Box {...{ flex: '1' }}>{trackInfo.name}</Box>
																	<Box {...{ flex: '1', display: { base: 'none', lg: 'block' } }}>
																		<Link to={`/albums/${trackInfo.album_ID}`}>
																			<Text variant={'link'}>{albumInfo ? albumInfo.name : ''}</Text>
																		</Link>
																	</Box>
																</Flex>
																<Box {...{ w: '40px', textAlign: 'right' }}>{timeFormat(trackInfo.duration)}</Box>
																<Box {...{ w: '15px', justifyContent: 'center' }}>{isActive ? <Box {...{ h: '28px', w: '3px', bg: '#ffffff', borderRadius: '5px', boxShadow: '0 2px 7px 0 rgba(0, 0, 0, 0.50)' }} /> : null}</Box>
															</Flex>
														);
													} else return null;
											  })
											: null}
									</Box>
								</Box>
							</Box>
						</Flex>
					</AnimationFramer>
					<Box>
						<Box {...{ mb: '20px' }}>
							<AnimationFramer>
								<Heading as={'h2'} {...{ fontSize: 24, fontWeight: 'bold' }}>
									Albums
								</Heading>
							</AnimationFramer>
						</Box>
						<AlbumsList list={currentAlbums} />
					</Box>
				</Box>
			) : null}
		</Box>
	);
};

export default ArtistsSingle;
