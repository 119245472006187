const timeFormat = (value: number | string): string => {
	const duration = typeof value === 'string' ? parseFloat(value) : value;
	const hrs = ~~(duration / 3600);
	const mins = ~~((duration % 3600) / 60);
	const secs = ~~duration % 60;

	let ret = '';

	if (hrs > 0) {
		ret += '' + hrs + ':' + (mins < 10 ? '0' : '');
	}

	ret += '' + mins + ':' + (secs < 10 ? '0' : '');
	ret += '' + secs;
	return ret;
};

const searchFormat = (value: string): string => {
	return value.toLowerCase().replace(/[^a-zA-Z0-9]/g, '');
};

export { timeFormat, searchFormat };
