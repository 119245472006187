import { FC } from 'react';
import { Box, Container, Heading } from '@chakra-ui/react';

const PageNotFound: FC = () => {
	return (
		<Box>
			<Container>
				<Heading>404</Heading>
			</Container>
		</Box>
	);
};

export default PageNotFound;
