import { FC, MouseEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Flex, Box, SimpleGrid, Text, useColorModeValue } from '@chakra-ui/react';
import ButtonPlay from '../Buttons/Play';
import ImageLoader from '../ImageLoader';
import AnimationFramer from '../AnimationFramer';
import { playToggle, playlistSet } from '../../store';
import { IState, IAlbum, IArtist, ITrack } from '../../types';

const ArtistsList: FC<{ list: IArtist[] }> = ({ list }) => {
	const dispatch = useDispatch();
	const STATE = useSelector((state: IState) => state);
	const { playlistID, playing } = STATE;
	const { albums } = STATE.data;

	const artistHoverBg = useColorModeValue('rgba(255, 255, 255, 0.3)', 'rgba(0, 0, 0, 0.3)');

	const setArtistOnPlaylist = (event: MouseEvent<HTMLDivElement>, id: IArtist['id']) => {
		event.stopPropagation();
		if (playlistID === id) {
			dispatch(playToggle());
		} else {
			// For server
			const albumsByArtist: IAlbum[] = albums.filter(f => f.artist_IDs.includes(id)).sort((a: IAlbum, b: IAlbum) => parseInt(b.year) - parseInt(a.year));
			const tracksByArtist: ITrack['id'][] = [];
			albumsByArtist.forEach((album: IAlbum) => {
				tracksByArtist.push(...album.track_IDs);
			});
			if (tracksByArtist.length) {
				dispatch(playlistSet({ playlist: tracksByArtist, playlistID: id, activeTrack: tracksByArtist[0] }));
			}
		}
	};

	return (
		<SimpleGrid {...{ columns: { base: 2, lg: 3 }, templateColumns: { base: 'repeat(2, calc(50vw - 30px))', lg: 'repeat(3, calc(33vw - 30px))' }, columnGap: { base: '20px', lg: '25px' }, rowGap: { base: '30px', lg: '40px' } }}>
			{list.map((el: IArtist) => {
				const isActive = playing && playlistID === el.id;
				return (
					<AnimationFramer key={el.id}>
						<Flex {...{ textAlign: 'center', flexDirection: 'column', h: '100%' }}>
							<Flex {...{ mb: '10px', flex: '1 1', position: 'relative', borderRadius: '50%', boxShadow: 'inset 0 0 12px 1px rgba(0, 0, 0, 0.2)', p: '5px', overflow: 'hidden' }}>
								<ImageLoader imageID={el.image_ID} {...{ h: { base: 'calc(50vw - 40px)', lg: 'calc(33vw - 40px)' }, w: '100%', objectFit: 'cover', borderRadius: '50%' }} />
								<Flex
									onClick={event => setArtistOnPlaylist(event, el.id)}
									{...{ opacity: isActive ? 1 : 0, cursor: 'pointer', transition: 'all 0.2s ease-out', position: 'absolute', zIndex: 1, top: '0', bottom: '0', left: '0', right: '0', justifyContent: 'center', alignItems: 'center', _hover: { opacity: 1, bg: artistHoverBg } }}
								>
									<ButtonPlay isActive={isActive} />
								</Flex>
							</Flex>
							<Box {...{ fontSize: 14, fontWeight: 'medium' }}>
								<Link to={`/artists/${el.id}`}>
									<Text variant={'link'}>{el.name}</Text>
								</Link>
							</Box>
						</Flex>
					</AnimationFramer>
				);
			})}
		</SimpleGrid>
	);
};

export default ArtistsList;
