import React, { useEffect, useState, FC } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Box, Button, Icon, useColorModeValue } from '@chakra-ui/react';
import { CloudArrowDown, CloudArrowDownFill } from 'react-bootstrap-icons';
import { toastSet } from '../../../store';
import { DBget, DBset, DBdel } from '../../../db';
import Api from '../../../api';
import { IState, ITrack } from '../../../types';

const ButtonDownload: FC<{ ID: string; type: string; [propName: string]: any }> = ({ ID, type = 'track', ...props }) => {
	const dispatch = useDispatch();
	const STATE = useSelector((state: IState) => state);
	const { isOnline } = STATE;
	const { tracks } = STATE.data;
	const iconColor = useColorModeValue('#5D528C', '#ffffff');
	const [load, setLoad] = useState(true);
	const [hasCache, setHasCache] = useState(false);

	useEffect(() => {
		if (ID) {
			hasTrack();
		}
	}, [ID]);

	const hasTrack = async () => {
		const cacheBlob = await DBget('audio', ID);
		if (cacheBlob) {
			setHasCache(true);
		}
		setLoad(false);
	};

	const handleAddRemoveCache = async () => {
		const cacheBlob = await DBget('audio', ID);
		if (cacheBlob) {
			DBdel('audio', ID);
			setHasCache(false);
		} else {
			if (isOnline) {
				const trackInfo: ITrack | undefined = tracks.find(e => e.id === ID);
				if (trackInfo) {
					const res = await Api.getFile(trackInfo.audio_ID);
					const data = res.data;
					if (data.status === 'success') {
						const { buff } = data.result;
						DBset('audio', ID, buff);
						setHasCache(true);
					} else {
						dispatch(toastSet({ status: 'error', text: 'Error get file' }));
					}
				}
			} else {
				dispatch(toastSet({ status: 'error', text: 'Error get file, you offline' }));
			}
		}
	};

	if (!load) {
		return (
			<Button onClick={handleAddRemoveCache} {...props} {...{ p: 0, color: iconColor, bg: 'transparent', minWidth: '15px', opacity: 0.9, _hover: { opacity: 1 }, _focus: {}, _active: {} }}>
				<Icon as={hasCache ? CloudArrowDownFill : CloudArrowDown} />
			</Button>
		);
	}
	return <Box />;
};

export default ButtonDownload;
