import { useState, useEffect, useLayoutEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { imagesAdd, imagesAddLoad } from '../store';
import { DBset, DBget } from '../db';
import { IAlbum, IArtist, IState, ITrack, INavigatorMetaData } from '../types';
import Api from '../api';

const useWindowSize = (): [number, number] => {
	const [size, setSize] = useState<[number, number]>([0, 0]);
	useLayoutEffect(() => {
		const updateSize = () => {
			setSize([window.innerWidth, window.innerHeight]);
		};
		window.addEventListener('resize', updateSize);
		updateSize();
		return () => window.removeEventListener('resize', updateSize);
	}, []);
	return size;
};

const useImageSetter = () => {
	const dispatch = useDispatch();
	const { images, loadingImages, isOnline } = useSelector((state: IState) => state);
	const [load, setLoad] = useState(false);

	useEffect(() => {
		if (loadingImages.length && !load) {
			imageSetter();
		}
	}, [loadingImages, load]);

	const imageSetter = async () => {
		setLoad(true);
		const loadedQueue = [...loadingImages];
		for (const ID of loadedQueue) {
			if (ID && !images[ID]) {
				let imageBlob: boolean | string = false;
				const cacheBlob = await DBget('image', ID);
				if (cacheBlob) {
					imageBlob = cacheBlob;
				} else if (isOnline) {
					const res = await Api.getFile(ID);
					const data = res.data;
					if (data.status === 'success') {
						const { buff } = data.result;
						DBset('image', ID, buff);
						imageBlob = buff;
					}
				}
				if (imageBlob) {
					dispatch(imagesAdd({ ID, image: `data:image/jpeg;base64, ${imageBlob}` }));
				}
			}
		}
		setLoad(false);
	};
	return [load];
};

const useMediaSession = () => {
	const dispatch = useDispatch();
	const { data, images } = useSelector((state: IState) => state);
	const { artists, albums, tracks } = data;
	const [trackID, setTrackID] = useState('');
	const [loadedImage, setLoadedImage] = useState(false);

	useEffect(() => {
		if (trackID) {
			const trackInfo: ITrack | undefined = tracks.find(e => e.id === trackID);
			if (trackInfo && images[trackInfo.image_ID]) {
				setLoadedImage(true);
			}
		}
	}, [images, trackID, tracks]);

	useEffect(() => {
		if (trackID && loadedImage) {
			const trackInfo: ITrack | undefined = tracks.find(f => f.id === trackID);
			if (trackInfo) {
				const image: string = images[trackInfo.image_ID];
				const artistInfo: IArtist | undefined = artists.find(f => f.id === trackInfo.artist_IDs[0]);
				if (artistInfo) {
					document.title = `${trackInfo.name} • ${artistInfo.name}`;
				}
				if ('mediaSession' in navigator) {
					const albumInfo: IAlbum | undefined = albums.find(f => f.id === trackInfo.album_ID);
					const imageMimeType = image.includes('iVBORw0KGgo') ? 'png' : 'jpg';
					const metaData: INavigatorMetaData = {
						title: trackInfo.name,
						artist: artistInfo ? artistInfo.name : '',
						album: albumInfo ? albumInfo.name : '',
						artwork: [
							{ src: image, sizes: '96x96', type: `image/${imageMimeType}` },
							{ src: image, sizes: '128x128', type: `image/${imageMimeType}` },
							{ src: image, sizes: '192x192', type: `image/${imageMimeType}` },
							{ src: image, sizes: '256x256', type: `image/${imageMimeType}` },
							{ src: image, sizes: '384x384', type: `image/${imageMimeType}` },
							{ src: image, sizes: '512x512', type: `image/${imageMimeType}` },
						],
					};
					navigator.mediaSession.metadata = new MediaMetadata(metaData);
				}
			}
			setLoadedImage(false);
		}
	}, [loadedImage]);

	useEffect(() => {
		if (trackID) {
			const trackInfo: ITrack | undefined = tracks.find(e => e.id === trackID);
			if (trackInfo) {
				if (images[trackInfo.image_ID]) {
					setLoadedImage(true);
				} else {
					dispatch(imagesAddLoad(trackInfo.image_ID));
				}
			}
		} else {
			document.title = `Webkoi Music`;
		}
	}, [trackID]);

	const handleSetTrackID = (ID: string): void => {
		setTrackID(ID);
	};

	return [handleSetTrackID];
};

export { useImageSetter, useMediaSession, useWindowSize };
