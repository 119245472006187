import axios from 'axios';

const baseURL = process.env.REACT_APP_API;
const api = () => {
	const token = localStorage.getItem('token');
	return axios.create({
		baseURL,
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			AuthToken: token,
		},
	});
};

const upload = () => {
	const token = localStorage.getItem('token');
	return axios.create({
		baseURL,
		headers: {
			'Content-Type': 'multipart/form-data',
			AuthToken: token,
		},
	});
};

interface IPayloadLogin {
	email: string;
	password: string;
}

interface IPayloadSetFavorite {
	id: string;
	type: string;
}

//AUTH
const login = (payload: IPayloadLogin) => api().post(`/login`, payload);
const session = () => api().get('/session');
const getInfo = () => api().get('/info');

const getFile = (ID: string) => api().get(`/file/${ID}`);

const setFavorite = (payload: IPayloadSetFavorite) => api().post('/favorite', payload);

const Api = {
	login,
	session,
	getInfo,
	getFile,
	setFavorite,
};

export default Api;
