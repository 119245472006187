import React, { useEffect, useState, useMemo, TouchEvent } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';
import { Box, Flex, Text, Button, Icon, Skeleton, Heading, useColorModeValue } from '@chakra-ui/react';
import { Slider, SliderTrack, SliderFilledTrack, SliderThumb } from '@chakra-ui/react';
import { MusicNoteList, Shuffle, RewindFill, Rewind, FastForwardFill, FastForward, Bookmark, BookmarkFill, PauseCircleFill, PlayCircleFill, Repeat } from 'react-bootstrap-icons';
import { timeFormat } from '../../tools';
import ImageLoader from '../../components/ImageLoader';
import { useWindowSize, useMediaSession } from '../../hooks';
import { login, playToggle, playlistSet, playlistShowToggle, toastSet, scrollSet } from '../../store';
import { DBset, DBget } from '../../db';
import Api from '../../api';
import { IAlbum, IArtist, IState, ITrack } from '../../types';

const shuffle = (array: string[]) => {
	for (let i = array.length - 1; i > 0; i--) {
		const j = Math.floor(Math.random() * (i + 1));
		[array[i], array[j]] = [array[j], array[i]];
	}
};

const Player = () => {
	const dispatch = useDispatch();
	const STATE = useSelector((state: IState) => state);
	const { isOnline, playing, activeTrack, playlist, playlistID, images } = STATE;
	const { tracks, albums, artists } = STATE.data;
	const { favorite_albums } = STATE.user;

	const [width] = useWindowSize();
	const [handleSetMediaTrackID] = useMediaSession();

	const [touchStartTrackBar, setTouchStartTrackBar] = useState<number | null>(null);
	const [touchEndTrackBar, setTouchEndTrackBar] = useState<number | null>(null);
	const [swipeStatusTrackBar, setSwipeStatusTrackBar] = useState(false);
	const [isSwipeTrackBar, setIsSwipeTrackBar] = useState(0);

	const [touchStartPlayingScreen, setTouchStartPlayingScreen] = useState<number | null>(null);
	const [touchEndPlayingScreen, setTouchEndPlayingScreen] = useState<number | null>(null);
	const [swipeStatusPlayingScreen, setSwipeStatusPlayingScreen] = useState(false);

	const [isOpenPlayingScreen, setIsOpenPlayingScreen] = useState(false);
	const [isShowPlaylistPlayingScreen, setIsShowPlaylistPlayingScreen] = useState(false);
	const [nextTracks, setNextTracks] = useState<string[]>([]);

	const [duration, setDuration] = useState(0);
	const [prevTrack, setPrevTrack] = useState(false);
	const [nextTrack, setNextTrack] = useState(false);
	const [isFavirite, setIsFavorite] = useState(false);

	const [playlistPlaying, setPlaylistPlaying] = useState<string[]>([]);
	const [currentAudioID, setCurrentAudioID] = useState<string | false>(false);
	const [currentAudio, setCurrentAudio] = useState<false | HTMLAudioElement>(false);
	const [timeAudio, setTimeAudio] = useState(0);
	const [prevAudio, setPrevAudio] = useState(false);
	const [nextAudio, setNextAudio] = useState(false);
	const [trackOver, setTrackOver] = useState(false);
	const [trackRepeat, setTrackRepeat] = useState(false);
	const [playlistShuffle, setPlaylistShuffle] = useState(false);

	const bg = useColorModeValue('rgba(30, 19, 74, 0.9)', 'rgba(20, 28, 65, 0.9)');
	const themeColor = useColorModeValue('#ffffff', '#0D1539');
	const playlistItemBg = useColorModeValue('#342a5c', '#131b41');
	const fontColor = useColorModeValue('#5B527F', '#626784');

	if ('mediaSession' in navigator) {
		navigator.mediaSession.setActionHandler('previoustrack', () => setPrevTrack(true));
		navigator.mediaSession.setActionHandler('nexttrack', () => setNextTrack(true));
		navigator.mediaSession.setActionHandler('pause', () => dispatch(playToggle()));
		navigator.mediaSession.setActionHandler('play', () => dispatch(playToggle()));
		navigator.mediaSession.setActionHandler('stop', () => {
			if (currentAudio) {
				if (playing) {
					dispatch(playToggle());
				}
				currentAudio.currentTime = 0;
			}
		});
		navigator.mediaSession.setActionHandler('seekbackward', () => {
			if (currentAudio) {
				currentAudio.currentTime = currentAudio.currentTime - 10 > 0 ? currentAudio.currentTime - 10 : 0;
			}
		});
		navigator.mediaSession.setActionHandler('seekforward', () => {
			if (currentAudio) {
				currentAudio.currentTime = currentAudio.currentTime + 10 < currentAudio.duration ? currentAudio.currentTime + 10 : currentAudio.duration;
			}
		});
		navigator.mediaSession.setActionHandler('seekto', (details: { action: MediaSessionAction; fastSeek?: boolean; seekOffset?: number; seekTime?: number }): void => {
			if (currentAudio && details.seekTime !== undefined) {
				currentAudio.currentTime = details.seekTime;
			}
		});
	}

	// Меняет цвет темы на цвет плейлиста
	useEffect(() => {
		dispatch(scrollSet(!isOpenPlayingScreen));

		const color = isOpenPlayingScreen ? playlistItemBg : themeColor;
		const metaThemeColor = document.querySelector('meta[name=theme-color]');
		if (metaThemeColor) {
			metaThemeColor.setAttribute('content', color);
		}
	}, [isOpenPlayingScreen]);

	// Новый плейлист => Ставит плейлист, активный трек, кнопки (пред след)
	useEffect(() => {
		if (activeTrack && playlist && playlist.length) {
			const trackPosition: number = playlist.findIndex(e => e === activeTrack);
			if (~trackPosition) {
				if (playlist[trackPosition + 1]) {
					setNextAudio(true);
				}
				if (playlist[trackPosition - 1]) {
					setPrevAudio(true);
				}
			}

			setPlaylistPlaying(playlist);
			setCurrentAudioID(activeTrack);

			if (!currentAudio) {
				setCurrentAudio(new Audio());
			}
		}
	}, [playlist]);

	// Проверяет что есть трек (альбом) в избранных
	useEffect(() => {
		if (activeTrack) {
			const trackInfo: ITrack | undefined = tracks.find(f => f.id === activeTrack);
			if (trackInfo) {
				setIsFavorite(!!~favorite_albums.findIndex(e => e === trackInfo.album_ID));
			}
		}
	}, [favorite_albums, tracks, activeTrack]);

	// Меняется трек, ставит его в аудио и ставит кнопки (пред след)
	useEffect(() => {
		if (activeTrack && currentAudioID && activeTrack !== currentAudioID) {
			const trackPosition: number = playlist.findIndex(e => e === activeTrack);
			if (~trackPosition) {
				setNextAudio(!!playlist[trackPosition + 1]);
				setPrevAudio(!!playlist[trackPosition - 1]);
			}
			setCurrentAudioID(activeTrack);
		}
	}, [activeTrack]);

	// В аудио ИД новый трек, загружает его, если играл до этого, то ставит на паузу
	useEffect(() => {
		if (currentAudioID) {
			if (currentAudio) {
				currentAudio.pause();
			}
			getTrackBlob(currentAudioID);
		}
	}, [currentAudioID]);

	// Включение / выключение проигрывания
	useEffect(() => {
		if (currentAudio) {
			if (playing) {
				currentAudio.play();
			} else {
				currentAudio.pause();
			}
		}
	}, [playing]);

	// Включет / Выключает случайный порядок, пред не включает в очередь
	// TODO при включении нового плейлиста делать также случайный порядок
	useEffect(() => {
		if (playlistShuffle) {
			const prevAudioArr: string[] = [];
			const nextAudioArr: string[] = [];
			let randomAdd = false;
			playlistPlaying.forEach(e => {
				if (e === currentAudioID) {
					randomAdd = true;
				}
				if (randomAdd) {
					nextAudioArr.push(e);
				} else {
					prevAudioArr.push(e);
				}
			});
			shuffle(nextAudioArr);
			setPlaylistPlaying(prevAudioArr.concat(nextAudioArr));
		} else {
			setPlaylistPlaying(playlist);
		}
	}, [playlistShuffle]);

	// Если закончился трек ставит следующий, проверяет что нет Повторения
	useEffect(() => {
		if (trackOver) {
			if (trackRepeat && currentAudio) {
				currentAudio.currentTime = 0;
				currentAudio.play();
			} else {
				setNextTrack(true);
			}
			setTrackOver(false);
		}
	}, [trackOver]);

	// Ставит время, громкость для Аудио и уведомляет о завершении трека
	// TODO узнать удаляет ли евент прошлого трека
	useEffect(() => {
		if (currentAudio) {
			currentAudio.volume = 1;
			currentAudio.ontimeupdate = () => {
				if (currentAudio.duration) {
					setTimeAudio(Math.floor(currentAudio.currentTime));
					setDuration(currentAudio.currentTime / (currentAudio.duration / 100));
				}
				if (currentAudio.ended) {
					setTrackOver(true);
				}
			};
		}
	}, [currentAudio]);

	// Получение файла из базы или сервера
	const getTrackBlob = async (ID: string) => {
		if (ID) {
			let trackBlob: false | string = false;
			const trackPosition: number = playlistPlaying.findIndex(e => e === ID);
			const nextTrackID: string = playlistPlaying[trackPosition + 1];
			const cacheBlob = await DBget('audio', ID);
			if (cacheBlob) {
				trackBlob = cacheBlob;
			} else if (isOnline) {
				const trackInfo: ITrack | undefined = tracks.find(e => e.id === ID);
				if (trackInfo) {
					const res = await Api.getFile(trackInfo.audio_ID);
					const data = res.data;
					if (data.status === 'success') {
						const { buff } = data.result;
						DBset('audio', ID, buff);
						trackBlob = buff;
					} else {
						dispatch(toastSet({ status: 'error', text: 'Error get file in Playlist' }));
					}
				}
			} else {
				dispatch(toastSet({ status: 'error', text: 'Error get file in Playlist, you offline' }));
			}
			if (trackBlob) {
				handleSetMediaTrackID(ID);

				if (currentAudio) {
					const trackUrl = `data:audio/mpeg;base64,${trackBlob}`;
					currentAudio.src = trackUrl;
					currentAudio.play();
				}
			}
			if (nextTrackID) {
				const cacheBlob = await DBget('audio', nextTrackID);
				if (!cacheBlob) {
					if (isOnline) {
						const nextTrackInfo: ITrack | undefined = tracks.find(e => e.id === nextTrackID);
						if (nextTrackInfo) {
							const res = await Api.getFile(nextTrackInfo.audio_ID);
							const data = res.data;
							if (data.status === 'success') {
								const { buff } = data.result;
								DBset('audio', nextTrackID, buff);
							} else {
								dispatch(toastSet({ status: 'error', text: 'Error get file for Next track in Playlist' }));
							}
						}
					} else {
						dispatch(toastSet({ status: 'error', text: 'Error get file for Next track in Playlist, you offline' }));
					}
				}
			}
		}
	};

	// Скидывает плеер
	const resetPlayer = () => {
		if (currentAudio) {
			currentAudio.pause();
			if (playing) {
				dispatch(playToggle());
			}
		}
		setTimeAudio(0);
	};

	// Вызывает пред трек
	useEffect(() => {
		if (prevTrack) {
			handlePrevTrack();
			setPrevTrack(false);
		}
	}, [prevTrack]);

	// Проверяет и ставит пред трек
	const handlePrevTrack = () => {
		const trackPosition: number = playlistPlaying.findIndex(e => e === currentAudioID);
		const track: string | undefined = playlistPlaying[trackPosition - 1];
		if (~trackPosition && track) {
			setCurrentAudioID(track);
			dispatch(playlistSet({ activeTrack: track }));
			setNextAudio(true);
			if (playlist[trackPosition - 2]) {
				setPrevAudio(true);
			} else {
				setPrevAudio(false);
			}
		}
	};

	// Вызывает след трек
	useEffect(() => {
		if (nextTrack) {
			handleNextTrack();
			setNextTrack(false);
		}
	}, [nextTrack]);

	// Проверяет и ставит след трек, если нет то скидывает плеер
	const handleNextTrack = () => {
		const trackPosition: number = playlistPlaying.findIndex(e => e === currentAudioID);
		const track: string | undefined = playlistPlaying[trackPosition + 1];
		if (~trackPosition && track) {
			setCurrentAudioID(track);
			dispatch(playlistSet({ activeTrack: track }));
			setPrevAudio(true);
			if (playlist[trackPosition + 2]) {
				setNextAudio(true);
			} else {
				setNextAudio(false);
			}
		} else {
			resetPlayer();
		}
	};

	// Если новый плейлист то ставит кнопки
	useEffect(() => {
		const trackPosition: number = playlistPlaying.findIndex(e => e === currentAudioID);
		if (~trackPosition) {
			setPrevAudio(!!playlist[trackPosition - 1]);
			setNextAudio(!!playlist[trackPosition + 1]);
		}
	}, [playlistPlaying]);

	// Следующие треки для списка
	useEffect(() => {
		const trackPosition: number = playlistPlaying.findIndex(e => e === activeTrack);
		if (~trackPosition) {
			setNextTracks(playlistPlaying.slice(trackPosition + 1));
		}
	}, [playlistPlaying, activeTrack]);

	// Поставить / Удалить из избранного
	const handleToggleFavorite = (activeTrack: string) => {
		if (activeTrack && isOnline) {
			const trackInfo: ITrack | undefined = tracks.find(f => f.id === activeTrack);
			if (trackInfo) {
				const albumID = trackInfo.album_ID;
				setIsFavorite(!isFavirite);
				Api.setFavorite({ id: albumID, type: 'albums' })
					.then(res => {
						const data = res.data;
						if (data.status === 'success') {
							const { user } = data.result;
							dispatch(login(user));
						} else {
							dispatch(toastSet({ status: 'error', text: 'Error' }));
						}
					})
					.catch(error => {
						dispatch(toastSet({ status: 'error', text: error.response && error.response.data && error.response.data.message ? error.response.data.message : error.message }));
					});
			}
		} else {
			dispatch(toastSet({ status: 'error', text: 'You offline' }));
		}
	};

	// Свайп трекбара
	const handleOnTouchStartTrackBar = (e: TouchEvent) => {
		setSwipeStatusTrackBar(false);
		setTouchEndTrackBar(null);
		setTouchStartTrackBar(e.targetTouches[0].clientX);
	};
	const handleOnTouchMoveTrackBar = (e: TouchEvent) => {
		setTouchEndTrackBar(e.targetTouches[0].clientX);
	};
	const handleOnTouchEndTrackBar = () => {
		setSwipeStatusTrackBar(true);
	};
	useEffect(() => {
		if (swipeStatusTrackBar) {
			if (!touchStartTrackBar || !touchEndTrackBar) return;
			const distance = touchStartTrackBar - touchEndTrackBar;
			const isLeftSwipe = distance > 30;
			const isRightSwipe = distance < -30;

			if (isRightSwipe && prevAudio) {
				setIsSwipeTrackBar(-1);
			} else if (isLeftSwipe && nextAudio) {
				setIsSwipeTrackBar(1);
			}
		}
	}, [swipeStatusTrackBar]);
	useEffect(() => {
		if (isSwipeTrackBar > 0) {
			setNextTrack(true);
		} else if (isSwipeTrackBar < 0) {
			setPrevTrack(true);
		}
	}, [isSwipeTrackBar]);

	// Свайп плейскрина
	const handleOnTouchStartPlayingScreen = (e: TouchEvent) => {
		setSwipeStatusPlayingScreen(false);
		setTouchEndPlayingScreen(null);
		setTouchStartPlayingScreen(e.targetTouches[0].clientY);
	};
	const handleOnTouchMovePlayingScreen = (e: TouchEvent) => {
		setTouchEndPlayingScreen(e.targetTouches[0].clientY);
	};
	const handleOnTouchEndPlayingScreen = () => {
		setSwipeStatusPlayingScreen(true);
	};
	useEffect(() => {
		if (swipeStatusPlayingScreen) {
			if (!touchStartPlayingScreen || !touchEndPlayingScreen) return;
			const distance = touchStartPlayingScreen - touchEndPlayingScreen;
			const isDownSwipe = distance < -30;

			if (isDownSwipe && isOpenPlayingScreen) {
				setIsOpenPlayingScreen(false);
			}
		}
	}, [swipeStatusPlayingScreen]);

	// Кнопка для скрытия плейлиста
	const handleClickOnLink = () => {
		setIsOpenPlayingScreen(false);
	};

	const renderInfo = useMemo(() => {
		if (activeTrack) {
			const trackInfo: ITrack | undefined = tracks.find(f => f.id === activeTrack);
			if (trackInfo) {
				const artistsArr: IArtist[] = [];
				trackInfo.artist_IDs.forEach(id => {
					const artistInfo: IArtist | undefined = artists.find(f => f.id === id);
					if (artistInfo) artistsArr.push(artistInfo);
				});
				return (
					<Flex {...{ flex: 1, gap: '10px', alignItems: 'center' }} onClick={() => setIsOpenPlayingScreen(true)}>
						<Box {...{ borderRadius: '4px', h: '50px', w: '50px', boxShadow: '0 2px 6px 0 rgba(0, 0, 0, 0.50)', overflow: 'hidden' }}>
							<ImageLoader imageID={trackInfo.image_ID} {...{ h: '50px', w: '50px' }} />
						</Box>
						<Box {...{ flex: 1, w: 'calc(100vw - (40px + 50px + 10px + 85px + 20px) )' }}>
							<Flex {...{ fontSize: 12, color: fontColor, gap: '5px' }}>{artistsArr.map((a, ai) => a.name)}</Flex>
							<Text {...{ fontSize: 13, color: '#ffffff', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>{trackInfo.name}</Text>
						</Box>
					</Flex>
				);
			}
		}
		return (
			<Flex {...{ flex: 1, gap: '10px', alignItems: 'center' }}>
				<Skeleton {...{ mr: '5px', borderRadius: '4px', h: '50px', w: '50px', boxShadow: '0 2px 6px 0 rgba(0, 0, 0, 0.50)' }} />
				<Box>
					<Skeleton {...{ mb: '5px', w: '100px', h: '20px' }} />
					<Skeleton {...{ w: '70px', h: '16px' }} />
				</Box>
			</Flex>
		);
	}, [activeTrack]);

	const renderPlayingScreenInfoPlaylist = useMemo(() => {
		return isShowPlaylistPlayingScreen ? (
			<Box {...{ flex: 1, overflow: 'hidden', position: 'relative' }}>
				<motion.div
					initial={{ y: '100%', opacity: 0 }}
					animate={{ y: 0, opacity: 1 }}
					transition={{
						ease: 'easeOut',
						duration: 0.3,
						delay: 0.1,
					}}
				>
					<Box onTouchStart={handleOnTouchStartPlayingScreen} onTouchMove={handleOnTouchMovePlayingScreen} onTouchEnd={handleOnTouchEndPlayingScreen} {...{ pt: '20px', pb: '20px' }}>
						<Heading as={'h2'} {...{ fontSize: 24, fontWeight: 'bold', color: '#ffffff' }}>
							Your Queue
						</Heading>
					</Box>
					<Box {...{ h: 'calc(100vh - (420px))', overflowY: 'auto', pb: '25px', mx: '-10px' }}>
						{nextTracks.length
							? nextTracks.map((e, i) => {
									const trackInfo: ITrack | undefined = tracks.find(f => f.id === e);
									if (trackInfo) {
										const artistsArr: IArtist[] = [];
										trackInfo.artist_IDs.forEach(id => {
											const artistInfo: IArtist | undefined = artists.find(f => f.id === id);
											if (artistInfo) artistsArr.push(artistInfo);
										});
										return (
											<Flex key={e + activeTrack} onClick={() => dispatch(playlistSet({ activeTrack: e }))} {...{ width: '100%', px: '10px', h: '70px', gap: '20px', alignItems: 'center', fontSize: 15, cursor: 'grab', color: '#CCD1E5' }}>
												<Flex {...{ justifyContent: 'center', alignItems: 'center', flex: isShowPlaylistPlayingScreen ? 'none' : 1 }}>
													<ImageLoader imageID={trackInfo.image_ID} {...{ h: '50px', w: '50px', objectFit: 'cover', borderRadius: '8px', boxShadow: '0 2px 6px 0 rgba(0, 0, 0, 0.50)' }} />
												</Flex>
												<Flex {...{ flexDirection: 'column', justifyContent: 'center', fontSize: 16, w: 'calc(100vw - (60px + 70px + 20px))' }}>
													<Text {...{ color: '#ffffff', overflow: 'hidden', whiteSpace: isShowPlaylistPlayingScreen ? 'nowrap' : 'normal', textOverflow: 'ellipsis' }}>{trackInfo.name}</Text>
													<Text {...{ color: fontColor, overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>{artistsArr.map(artistInfo => artistInfo.name)}</Text>
												</Flex>
											</Flex>
										);
									} else return null;
							})
							: null}
					</Box>
					<Box {...{ position: 'absolute', bottom: '-5px', left: 0, right: 0, h: '30px', bg: `linear-gradient(0deg, ${playlistItemBg} 0%, rgb(255, 255, 255, 0%) 100%)` }} />
				</motion.div>
			</Box>
		) : null;
	}, [isShowPlaylistPlayingScreen, nextTracks, activeTrack]);

	const renderPlayingScreenInfo = useMemo(() => {
		if (activeTrack) {
			const trackInfo: ITrack | undefined = tracks.find(f => f.id === activeTrack);
			if (trackInfo) {
				const albumInfo: IAlbum | undefined = albums.find(f => f.id === trackInfo.album_ID);
				const artistsArr: IArtist[] = [];
				trackInfo.artist_IDs.forEach(id => {
					const artistInfo: IArtist | undefined = artists.find(f => f.id === id);
					if (artistInfo) artistsArr.push(artistInfo);
				});
				return (
					<Flex {...{ flex: 1, flexDirection: 'column' }}>
						<Flex
							onTouchStart={handleOnTouchStartPlayingScreen}
							onTouchMove={handleOnTouchMovePlayingScreen}
							onTouchEnd={handleOnTouchEndPlayingScreen}
							{...{ flexDirection: isShowPlaylistPlayingScreen ? 'row' : 'column', h: isShowPlaylistPlayingScreen ? 'auto' : '100%', gap: '20px', mb: isShowPlaylistPlayingScreen ? '10px' : 0 }}
						>
							<Flex {...{ justifyContent: 'center', alignItems: 'center', flex: isShowPlaylistPlayingScreen ? 'none' : 1 }}>
								<ImageLoader
									imageID={trackInfo.image_ID}
									{...{ h: isShowPlaylistPlayingScreen ? '70px' : '300px', w: isShowPlaylistPlayingScreen ? '70px' : '300px', objectFit: 'cover', borderRadius: isShowPlaylistPlayingScreen ? '8px' : '12px', boxShadow: '0 2px 6px 0 rgba(0, 0, 0, 0.50)', transition: 'all 0.2s ease-out' }}
								/>
							</Flex>
							<Flex {...{ overflow: 'hidden', flexDirection: 'column', justifyContent: 'center', mb: isShowPlaylistPlayingScreen ? 0 : '20px', w: isShowPlaylistPlayingScreen ? 'calc(100vw - (50px + 70px + 20px))' : 'auto' }}>
								<Text {...{ color: '#ffffff', fontSize: isShowPlaylistPlayingScreen ? 18 : 20 }}>{trackInfo.name}</Text>
								{isShowPlaylistPlayingScreen ? (
									<Text {...{ color: fontColor, fontSize: 16, overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>{artistsArr.map(artistInfo => artistInfo.name)}</Text>
								) : (
									<Flex {...{ gap: '15px', color: fontColor }}>
										{albumInfo ? (
											<Link onClick={handleClickOnLink} to={`/albums/${albumInfo.id}`}>
												{albumInfo.name}
											</Link>
										) : null}
										<Text>|</Text>
										<Flex {...{ gap: '5px' }}>
											{artistsArr.map(artistInfo => (
												<Link key={artistInfo.id} onClick={handleClickOnLink} to={`/artists/${artistInfo.id}`}>
													{artistInfo.name}
												</Link>
											))}
										</Flex>
									</Flex>
								)}
							</Flex>
						</Flex>
						{isShowPlaylistPlayingScreen && albumInfo ? (
							<Box onTouchStart={handleOnTouchStartPlayingScreen} onTouchMove={handleOnTouchMovePlayingScreen} onTouchEnd={handleOnTouchEndPlayingScreen} {...{ fontSize: 16, w: 'calc(100vw - (50px + 70px + 20px))', color: fontColor }}>
								<Text {...{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
									Album:{' '}
									<Link onClick={handleClickOnLink} to={`/albums/${albumInfo.id}`}>
										{albumInfo.name}
									</Link>
								</Text>
							</Box>
						) : null}
						{renderPlayingScreenInfoPlaylist}
					</Flex>
				);
			}
		}
		return null;
	}, [activeTrack, albums, artists, fontColor, images, tracks, isShowPlaylistPlayingScreen, renderPlayingScreenInfoPlaylist]);

	const renderPlayingScreenActionsButtons = useMemo(() => {
		if (activeTrack) {
			return (
				<Flex {...{ justifyContent: 'space-between', alignItems: 'center' }}>
					<Button variant={'palyerButtonToggle'} className={playlistShuffle ? 'active' : ''} onClick={() => setPlaylistShuffle(!playlistShuffle)}>
						<Icon as={Shuffle} {...{ fontSize: 20 }} />
					</Button>
					<Button variant={'palyerButtonToggle'} className={trackRepeat ? 'active' : ''} onClick={() => setTrackRepeat(!trackRepeat)}>
						<Icon as={Repeat} {...{ fontSize: 20 }} />
					</Button>
					<Button variant={'palyerButtonToggle'} className={isFavirite ? 'active' : ''} onClick={() => handleToggleFavorite(activeTrack)}>
						<Icon as={isFavirite ? BookmarkFill : Bookmark} {...{ fontSize: 20 }} />
					</Button>
					<Button variant={'palyerButtonToggle'} className={isShowPlaylistPlayingScreen ? 'active' : ''} onClick={() => setIsShowPlaylistPlayingScreen(!isShowPlaylistPlayingScreen)}>
						<Icon as={MusicNoteList} {...{ fontSize: 20 }} />
					</Button>
				</Flex>
			);
		}
		return null;
	}, [playlistShuffle, trackRepeat, isFavirite, isShowPlaylistPlayingScreen, activeTrack]);

	const renderPlayingScreenPlayButtons = useMemo(() => {
		return (
			<Flex {...{ gap: '40px', alignItems: 'center', justifyContent: 'center', mb: '40px' }}>
				<Button variant={'palyerButton'} onClick={() => (!prevTrack ? setPrevTrack(true) : null)} isDisabled={!prevAudio}>
					<Icon as={prevAudio ? RewindFill : Rewind} {...{ fontSize: 45 }} />
				</Button>
				<Button variant={'palyerButton'} onClick={() => dispatch(playToggle())}>
					<Icon as={playing ? PauseCircleFill : PlayCircleFill} {...{ fontSize: 60 }} />
				</Button>
				<Button variant={'palyerButton'} onClick={() => (!nextTrack ? setNextTrack(true) : null)} isDisabled={!nextAudio}>
					<Icon as={nextAudio ? FastForwardFill : FastForward} {...{ fontSize: 45 }} />
				</Button>
			</Flex>
		);
	}, [prevAudio, playing, nextAudio, nextTrack, prevTrack]);

	const renderPlayingScreenSliderTrack = useMemo(() => {
		if (!isShowPlaylistPlayingScreen) {
			return (
				<Flex {...{ flexDirection: 'column', gap: '10px', mb: '30px' }}>
					<Flex {...{ w: '100%' }}>
						<Slider aria-label="slider-duration" min={0} max={100} value={duration} onChange={value => (currentAudio ? (currentAudio.currentTime = value * (currentAudio.duration / 100)) : null)} focusThumbOnChange={false}>
							<SliderTrack {...{ bg: 'rgba(255, 255, 255, 0.3)', h: '2px', boxShadow: '0 2px 2px 0 rgba(0, 0, 0, 0.1)' }}>
								<SliderFilledTrack {...{ bg: 'white' }} />
							</SliderTrack>
							<SliderThumb {...{ w: '15px', h: '15px', opacity: 0, _hover: { opacity: 1 }, _focus: { opacity: 1 }, _active: { opacity: 1 } }} />
						</Slider>
					</Flex>
					<Flex {...{ justifyContent: 'space-between', alignItems: 'center', color: fontColor }}>
						<Text>{timeAudio ? timeFormat(timeAudio) : '0:00'}</Text>
						<Text>{currentAudio && currentAudio.duration ? timeFormat(currentAudio.duration) : '0:00'}</Text>
					</Flex>
				</Flex>
			);
		}
		return null;
	}, [currentAudio, duration, timeAudio, isShowPlaylistPlayingScreen]);

	const renderPlayingScreen = useMemo(() => {
		if (activeTrack) {
			return (
				<Box {...{ position: 'fixed', zIndex: 201, bottom: isOpenPlayingScreen ? 0 : '-100%', left: 0, right: 0, height: '100vh', bg: playlistItemBg, transition: 'all 0.2s ease-out' }}>
					<Flex {...{ flexDirection: 'column', height: '100vh', pt: '10px', pb: '60px', px: '25px' }}>
						<Flex onTouchStart={handleOnTouchStartPlayingScreen} onTouchMove={handleOnTouchMovePlayingScreen} onTouchEnd={handleOnTouchEndPlayingScreen} onClick={() => setIsOpenPlayingScreen(false)} {...{ mb: '20px', justifyContent: 'center', alignItems: 'center', py: '10px' }}>
							<Box {...{ w: '60px', h: '6px', bg: '#ffffff', opacity: 0.5, borderRadius: '4px', _active: { opacity: 1 } }} />
						</Flex>
						{renderPlayingScreenInfo}
						{renderPlayingScreenSliderTrack}
						{renderPlayingScreenPlayButtons}
						{renderPlayingScreenActionsButtons}
					</Flex>
				</Box>
			);
		}
		return null;
	}, [activeTrack, renderPlayingScreenInfo, renderPlayingScreenSliderTrack, renderPlayingScreenPlayButtons, renderPlayingScreenActionsButtons, isOpenPlayingScreen, playlistItemBg]);

	const renderTrackBar = useMemo(() => {
		if (activeTrack) {
			const variants = {
				enter: (swipeSide: number) => ({ x: swipeSide * width }),
				center: { x: 0 },
				exit: (swipeSide: number) => ({ x: -swipeSide * width }),
			};
			const swipeSide = isSwipeTrackBar ? isSwipeTrackBar : 1;
			setIsSwipeTrackBar(0);
			return (
				<Box {...{ position: 'relative' }} onTouchStart={handleOnTouchStartTrackBar} onTouchMove={handleOnTouchMoveTrackBar} onTouchEnd={handleOnTouchEndTrackBar}>
					<AnimatePresence initial={false} custom={swipeSide}>
						<motion.div
							key={activeTrack}
							style={{ position: 'absolute', width: 'calc(100vw - 40px)' }}
							custom={swipeSide}
							variants={variants}
							initial="enter"
							animate="center"
							exit="exit"
							transition={{
								x: { duration: 0.3 },
							}}
						>
							<Flex {...{ alignItems: 'center', gap: '20px' }}>
								{renderInfo}
								<Flex {...{ mr: { base: 0, lg: '100px' }, gap: '20px', alignItems: 'center' }}>
									<Button variant={'palyerButton'} onClick={() => dispatch(playToggle())}>
										<Icon as={playing ? PauseCircleFill : PlayCircleFill} {...{ fontSize: 40 }} />
									</Button>
								</Flex>
							</Flex>
						</motion.div>
					</AnimatePresence>
				</Box>
			);
		}
	}, [activeTrack, width, playing, renderInfo]);

	return (
		<>
			<Box {...{ display: playlistID ? 'block' : 'none', position: 'fixed', zIndex: 200, bottom: '78px', left: 0, right: 0, h: '70px', px: '20px', py: '10px', bg: bg, backdropFilter: 'blur(5px)', borderY: '1px solid rgba(255, 255, 255, 0.1)' }}>{renderTrackBar}</Box>
			{renderPlayingScreen}
		</>
	);
};

export default Player;
