import { useEffect, useState, useMemo, FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Heading, Box, Flex, Input, IconButton, Text, Button, Icon, useColorModeValue } from '@chakra-ui/react';
import { Search2Icon, SmallCloseIcon } from '@chakra-ui/icons';
import { PlayCircleFill, PauseCircleFill } from 'react-bootstrap-icons';
import PageHeading from '../../components/PageHeading';
import AnimationFramer from '../../components/AnimationFramer';
import AtristsList from '../../components/AtristsList';
import AlbumsList from '../../components/AlbumsList';
import ImageLoader from '../../components/ImageLoader';
import { timeFormat, searchFormat } from '../../tools';
import { playToggle, playlistSet } from '../../store';
import { IAlbum, IArtist, IState, ITrack } from '../../types';

const Search: FC = () => {
	const dispatch = useDispatch();
	const STATE = useSelector((state: IState) => state);
	const { playing, playlistID, activeTrack } = STATE;
	const { artists, albums, tracks } = STATE.data;

	const [load, setLoad] = useState(true);

	const [searchValue, setSearchValue] = useState('');
	const [tracksList, setTracksList] = useState<ITrack[]>([]);
	const [artistsList, setArtistsList] = useState<IArtist[]>([]);
	const [albumsList, setAlbumsList] = useState<IAlbum[]>([]);

	const searchBg = useColorModeValue('#F5F5F5', '#192452');
	const searchColor = useColorModeValue('#CBCACF', '#284571');
	const playlistActiveItemBg = useColorModeValue('#5D528C', '#192452');
	const fontColor = useColorModeValue('#727377', '#CCD1E5');

	useEffect(() => {
		const currentValue = searchFormat(searchValue);
		if (currentValue.length > 2) {
			const searchArtists: IArtist[] = artists.filter((f: IArtist) => searchFormat(f.name).includes(currentValue));
			const searchTracks: ITrack[] = tracks.filter((f: ITrack) => {
				if (searchFormat(f.name).includes(currentValue)) return true;
				let hasArtist = false;
				searchArtists.forEach((artist: IArtist) => {
					if (f.artist_IDs.includes(artist.id)) hasArtist = true;
				});
				return hasArtist;
			});

			const searchAlbums: IAlbum[] = albums.filter((f: IAlbum) => {
				if (searchFormat(f.name).includes(currentValue)) return true;
				let hasArtist = false;
				searchArtists.forEach((artist: IArtist) => {
					if (f.artist_IDs.includes(artist.id)) hasArtist = true;
				});
				return hasArtist;
			});
			setArtistsList(searchArtists);
			setTracksList(searchTracks);
			setAlbumsList(searchAlbums);
		}
	}, [searchValue]);

	useEffect(() => {
		if (load) {
			setLoad(false);
		}
	}, [STATE]);

	const renderTracksList = useMemo(() => {
		const setArtistOnPlaylist = (trackID: ITrack['id']) => {
			if (activeTrack === trackID) {
				dispatch(playToggle());
			} else if (`search#${searchFormat(searchValue)}` === playlistID && activeTrack !== trackID) {
				dispatch(playlistSet({ activeTrack: trackID }));
			} else {
				dispatch(playlistSet({ playlist: tracksList.map((el: ITrack) => el.id), playlistID: `search#${searchFormat(searchValue)}`, activeTrack: trackID }));
			}
		};

		if (tracksList.length) {
			return (
				<Box {...{ mb: '30px' }}>
					<Box {...{ mb: '30px' }}>
						<AnimationFramer>
							<Heading as={'h2'} {...{ fontSize: 32, fontWeight: 'bold' }}>
								Tracks
							</Heading>
						</AnimationFramer>
					</Box>
					<Box {...{ flex: 1, maxW: '1000px' }}>
						<Box {...{ h: '300px', w: '100%' }}>
							<Box className={'scroll_playlist'} {...{ h: '300px', overflowY: 'auto', mx: { base: '-10px', lg: 0 } }}>
								{tracksList.map((el: ITrack) => {
									const isActive = activeTrack === el.id;
									const albumInfo = albums.find((f: IAlbum) => f.id === el.album_ID);
									return (
										<Flex
											key={el.id}
											{...{
												width: '100%',
												h: '60px',
												gap: '10px',
												px: { base: '10px', lg: 0 },
												alignItems: 'center',
												fontSize: 15,
												color: isActive ? '#ffffff' : fontColor,
												background: isActive ? playlistActiveItemBg : 'transparent',
												borderRadius: isActive ? '4px' : '0',
												boxShadow: isActive ? '0 3px 4px 0 rgba(0, 0, 0, 0.05)' : 'none',
											}}
										>
											<Flex onClick={() => setArtistOnPlaylist(el.id)} {...{ justifyContent: 'center', alignItems: 'center', w: '50px', h: '50px', position: 'relative' }}>
												<ImageLoader imageID={el.image_ID} {...{ borderRadius: '4px', h: '40px', w: '40px', boxShadow: '0 2px 7px 0 rgba(0, 0, 0, 0.50)' }} />
												<Button variant={'palyerButton'} onClick={() => setArtistOnPlaylist(el.id)} {...{ position: 'absolute', borderRadius: '50%', bg: isActive ? '#192452' : '#141C41', opacity: 0, _hover: { opacity: 1 }, _focus: { opacity: 1 } }}>
													<Icon as={isActive && playing ? PauseCircleFill : PlayCircleFill} {...{ fontSize: 30 }} />
												</Button>
											</Flex>
											<Flex {...{ flex: '1', columnGap: '30px', rowGap: '2px', flexDirection: { base: 'column', xl: 'row' } }}>
												<Box {...{ flex: '1' }}>{el.name}</Box>
												<Box {...{ flex: '1', display: { base: 'none', lg: 'block' } }}>
													<Link to={`/albums/${el.album_ID}`}>
														<Text variant={'link'}>{albumInfo ? albumInfo.name : ''}</Text>
													</Link>
												</Box>
											</Flex>
											<Box {...{ w: '40px', textAlign: 'right' }}>{timeFormat(el.duration)}</Box>
											<Box {...{ w: '15px', justifyContent: 'center' }}>{isActive ? <Box {...{ h: '28px', w: '3px', bg: '#ffffff', borderRadius: '5px', boxShadow: '0 2px 7px 0 rgba(0, 0, 0, 0.50)' }} /> : null}</Box>
										</Flex>
									);
								})}
							</Box>
						</Box>
					</Box>
				</Box>
			);
		}
		return null;
	}, [tracksList]);

	const renderAlbumsList = useMemo(() => {
		if (albumsList.length) {
			return (
				<Box {...{ mb: '30px' }}>
					<Box {...{ mb: '30px' }}>
						<AnimationFramer>
							<Heading as={'h2'} {...{ fontSize: 32, fontWeight: 'bold' }}>
								Albums
							</Heading>
						</AnimationFramer>
					</Box>
					<AlbumsList list={albumsList} withArtists />
				</Box>
			);
		}
		return null;
	}, [albumsList]);

	const renderArtistsList = useMemo(() => {
		if (artistsList.length) {
			return (
				<Box {...{ mb: '30px' }}>
					<Box {...{ mb: '30px' }}>
						<AnimationFramer>
							<Heading as={'h2'} {...{ fontSize: 32, fontWeight: 'bold' }}>
								Artists
							</Heading>
						</AnimationFramer>
					</Box>
					<AtristsList list={artistsList} />
				</Box>
			);
		}
		return null;
	}, [artistsList]);

	return (
		<Box>
			<PageHeading title={'Search'} />
			{!load ? (
				<Box>
					<AnimationFramer>
						<Box {...{ mb: '30px' }}>
							<Flex {...{ background: searchBg, gap: '5px', alignItems: 'center', borderRadius: '20px', px: '20px', h: '30px' }}>
								<Search2Icon {...{ color: searchColor }} />
								<Input type={'text'} name={'search'} placeholder={'Search...'} value={searchValue} onChange={e => setSearchValue(e.target.value)} {...{ h: '30px', border: 'none', _focus: { boxShadow: 'none' } }} />
								<IconButton
									aria-label={'clear'}
									onClick={() => setSearchValue('')}
									icon={<SmallCloseIcon {...{ fontSize: 13 }} />}
									{...{ visibility: searchValue ? 'visible' : 'hidden', borderRadius: '50%', h: '15px', w: '15px', minW: '15px', background: searchColor, color: searchBg, fontSize: '12px' }}
								/>
							</Flex>
						</Box>
					</AnimationFramer>
					{renderTracksList}
					{renderArtistsList}
					{renderAlbumsList}
					{searchValue.length > 2 && !tracksList.length && !artistsList.length && !albumsList.length ? (
						<Box>
							<AnimationFramer>
								<Heading as={'h2'} {...{ fontSize: 32, fontWeight: 'bold' }}>
									No results
								</Heading>
							</AnimationFramer>
						</Box>
					) : null}
				</Box>
			) : null}
		</Box>
	);
};

export default Search;
