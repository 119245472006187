import { FC, ReactNode } from 'react';
import { motion } from 'framer-motion';

const animationProps = {
	initial: { y: 10, opacity: 0 },
	whileInView: { y: 0, opacity: 1 },
	viewport: { once: true },
	transition: {
		ease: 'easeOut',
		duration: 0.3,
		delay: 0.1,
	},
};

const AnimationFramer: FC<{ children: string | ReactNode; [propName: string]: any }> = ({ children, ...props }) => {
	return (
		<motion.div {...animationProps} {...props}>
			{children}
		</motion.div>
	);
};

export default AnimationFramer;
