import { useEffect, useState, useMemo, FC } from 'react';
import { useSelector } from 'react-redux';
import { Heading, Box, Flex, Text, Button, useColorModeValue } from '@chakra-ui/react';
import PageHeading from '../../components/PageHeading';
import AnimationFramer from '../../components/AnimationFramer';
import { IAlbum, IState } from '../../types';
import { DBclear, DBget, DBkeys } from '../../db';

//TODO Память (сколько , отчистить)
// Почта, Имя

const Account: FC = () => {
	const STATE = useSelector((state: IState) => state);
	const { albums } = STATE.data;
	const { name, email } = STATE.user;

	const [load, setLoad] = useState(true);

	const [audioKeys, setAudioKeys] = useState<string[]>([]);
	const [imageKeys, setImageKeys] = useState<string[]>([]);
	const [DBSizeUsage, setDBSizeUsage] = useState<number>(0);
	const [DBSizeQuota, setDBSizeQuota] = useState<number>(0);

	const color = useColorModeValue('#727377', '#CCD1E5');

	useEffect(() => {
		getStorageData();
	}, []);

	useEffect(() => {
		if (STATE) {
			setLoad(false);
		}
	}, [STATE]);

	//1000000 mb
	const byteSize = (str: string) => new Blob([str]).size;

	const getCacheSize = async (ID: string, type: string): Promise<number> => {
		let cacheBlob = await DBget(type, ID);
		console.log(cacheBlob.length / 1000000);
		const size = byteSize(cacheBlob);
		cacheBlob = '';
		return size;
	};

	const getCacheData = async (): Promise<void> => {
		const currentAudioKeys: string[] = await DBkeys('audio');
		const currentImageKeys: string[] = await DBkeys('image');
		let currentSize = 0;
		currentAudioKeys.concat(currentImageKeys).forEach(async (ID: string, idx: number) => {
			const type = idx < currentAudioKeys.length ? 'audio' : 'image';
			setTimeout(async () => {
				currentSize = currentSize + (await getCacheSize(ID, type));
				setDBSizeUsage(Math.round(currentSize / 10000) / 100);
			}, 500 * idx);
		});
		setAudioKeys(currentAudioKeys);
		setImageKeys(currentImageKeys);
	};

	const deleteCacheData = async (): Promise<void> => {
		await DBclear('audio');
		await DBclear('image');
		setDBSizeUsage(0);
	};

	const getStorageData = async () => {
		if (navigator && navigator.storage) {
			const storageData = await navigator.storage.estimate();
			const { quota, usage } = storageData;
			if (quota) {
				setDBSizeQuota(Math.round(quota / 10000) / 100);
			}
			if (usage) {
				setDBSizeUsage(Math.round(usage / 10000) / 100);
			}
		}
	};

	const renderProfile = useMemo(() => {
		return (
			<Box>
				<AnimationFramer>
					<Box {...{ mb: '30px' }}>
						<Heading as={'h2'} {...{ fontSize: 32, fontWeight: 'bold' }}>
							Your Profile
						</Heading>
					</Box>
					<Flex {...{ gap: '10px', fontSize: 18 }}>
						<Flex {...{ flexDirection: 'column', gap: '5px' }}>
							<Text {...{ color: color }}>Name:</Text>
							<Text {...{ color: color }}>Email:</Text>
						</Flex>
						<Flex {...{ flexDirection: 'column', gap: '5px' }}>
							<Text>{name}</Text>
							<Text>{email}</Text>
						</Flex>
					</Flex>
				</AnimationFramer>
			</Box>
		);
	}, [name, email]);

	const renderCacheData = useMemo(() => {
		return (
			<Box>
				<AnimationFramer>
					<Box {...{ mb: '30px' }}>
						<Heading as={'h2'} {...{ fontSize: 32, fontWeight: 'bold' }}>
							Cache Data
						</Heading>
					</Box>
					<Box>
						<Flex {...{ mb: '20px', gap: '10px', fontSize: 18 }}>
							<Flex {...{ flexDirection: 'column', gap: '5px', color: color }}>
								<Text>Quota:</Text>
								<Text>Usage:</Text>
								<Text>Audio:</Text>
								<Text>Iamge:</Text>
							</Flex>
							<Flex {...{ flexDirection: 'column', gap: '5px' }}>
								<Text>{DBSizeQuota} Mb</Text>
								<Text>{DBSizeUsage} Mb</Text>
								<Text>{audioKeys.length}</Text>
								<Text>{imageKeys.length}</Text>
							</Flex>
						</Flex>
						<Flex {...{ gap: '20px' }}>
							<Button onClick={getCacheData}>Calculate</Button>
							<Button onClick={deleteCacheData}>Clear</Button>
						</Flex>
					</Box>
				</AnimationFramer>
			</Box>
		);
	}, [DBSizeUsage, DBSizeQuota, audioKeys, imageKeys]);

	return (
		<Box>
			<PageHeading title={'Account'} />
			{!load ? (
				<Flex {...{ flexDirection: 'column', gap: '30px' }}>
					{renderProfile}
					{renderCacheData}
				</Flex>
			) : null}
		</Box>
	);
};

export default Account;
