import { FC } from 'react';
import { Box, Button, Icon, useColorModeValue } from '@chakra-ui/react';
import { PlayCircleFill, PauseCircleFill } from 'react-bootstrap-icons';

const ButtonPlay: FC<{ isActive: boolean; [propName: string]: any }> = ({ isActive, ...props }) => {
	const iconBg = useColorModeValue('#ffffff', '#192452');
	const iconColor = useColorModeValue('#5D528C', '#ffffff');
	return (
		<Button {...props} {...{ p: 0, borderRadius: '50%', minWidth: '50px', width: '50px', height: '50px', boxShadow: '0 5px 10px 5px rgba(0, 0, 0, 0.10)', _hover: {} }}>
			<Box {...{ bg: iconBg, borderRadius: '50%', width: '50px', height: '50px', color: iconColor, fontSize: 52, position: 'relative' }}>
				<Icon as={isActive ? PauseCircleFill : PlayCircleFill} {...{ position: 'absolute', top: '-1px', bottom: '-1px', left: '-1px', right: '-1px' }} />
			</Box>
		</Button>
	);
};

export default ButtonPlay;
