import React, { Suspense, FC } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { mode } from '@chakra-ui/theme-tools';
import { ChakraProvider, Box, extendTheme } from '@chakra-ui/react';
import Store from './store';
import Routes from './router';

const theme = extendTheme({
	fonts: {
		body: `'Roboto', sans-serif`,
	},
	components: {
		Button: {
			variants: {
				navArrow: (props: any) => ({
					fontSize: 24,
					bg: 'transparent',
					minWidth: '24px',
					width: '24px',
					height: '24px',
					color: mode('#5d528c', '#2086EB')(props),
					_hover: {
						background: mode('#ece0ff', '#001B51')(props),
					},
					_focus: {
						background: mode('#ece0ff', '#001B51')(props),
					},
					_disabled: {
						opacity: 1,
						color: '#ADAEB2',
					},
				}),
				palyerButton: {
					p: 0,
					color: '#ffffff',
					bg: 'transparent',
					minWidth: '20px',
					width: 'auto',
					height: 'auto',
					opacity: 0.8,
					_hover: {
						opacity: 1,
					},
					_focus: {},
					_disabled: {
						opacity: 0.6,
						_hover: {
							opacity: 0.6,
						},
					},
				},
				palyerButtonToggle: {
					p: 0,
					color: '#485381',
					bg: 'transparent',
					minWidth: '15px',
					width: 'auto',
					height: 'auto',
					opacity: 0.8,
					'&.active': {
						color: '#ffffff',
					},
					_hover: {
						opacity: 1,
					},
					_focus: {},
				},
			},
		},
		Text: {
			variants: {
				link: (props: any) => ({
					display: 'inline-block',
					transition: 'all 0.2s ease-out',
					_hover: {
						color: mode('#20A0D7', '#0CC5EF'),
					},
				}),
			},
		},
	},
});

const App: FC = () => {
	return (
		<Provider store={Store}>
			<BrowserRouter>
				<Suspense fallback={<div>Загрузка...</div>}>
					<ChakraProvider theme={theme}>
						<Box className={'App'}>
							<Routes />
						</Box>
					</ChakraProvider>
				</Suspense>
			</BrowserRouter>
		</Provider>
	);
};

export default App;
