import { useEffect, FC } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { login, logout, onlineSet } from '../store';
import { IState, IRoute } from '../types';
import Api from '../api';
import Layout from '../components/Layout';
import Library from '../pages/Library';
import Albums from '../pages/Albums';
import AlbumsSingle from '../pages/Albums/single';
import Artists from '../pages/Artists';
import ArtistsSingle from '../pages/Artists/single';
import Search from '../pages/Search';
import Account from '../pages/Account';
import PageNotFound from '../pages/PageNotFound';
import Login from '../pages/Login';

const routesArr = [
	{ path: '/', element: <Library />, exact: true, auth: true },
	{ path: '/albums', element: <Albums />, exact: true, auth: true },
	{ path: '/albums/:id', element: <AlbumsSingle />, exact: true, auth: true },
	{ path: '/artists', element: <Artists />, exact: true, auth: true },
	{ path: '/artists/:id', element: <ArtistsSingle />, exact: true, auth: true },
	{ path: '/library', element: <Library />, exact: true, auth: true },
	{ path: '/search', element: <Search />, exact: true, auth: true },
	{ path: '/account', element: <Account />, exact: true, auth: true },

	{ path: '/404', element: <PageNotFound />, exact: true, auth: true },
	{ path: '/404', redirect: true, auth: true },

	{ path: '/', element: <Login />, exact: true, auth: false },
	{ path: '/', redirect: true, auth: false },
];

const Router: FC = () => {
	const dispatch = useDispatch();
	const STATE = useSelector((state: IState) => state);

	useEffect(() => {
		if (STATE.initialization) {
			const isOnline: boolean = navigator.onLine;
			dispatch(onlineSet(isOnline));

			if (isOnline) {
				Api.session()
					.then(res => {
						const data = res.data;
						if (data.status === 'success') {
							const { token, user } = data.result;
							localStorage.setItem('token', token);
							localStorage.setItem('user', JSON.stringify(user));
							dispatch(login(user));
						} else {
							console.log('error session in router 1');
							dispatch(logout());
						}
					})
					.catch(error => {
						console.log('error session in router 2');
						dispatch(logout());
					});
			} else {
				const token: string | null = localStorage.getItem('token');
				const user: string | null = localStorage.getItem('user');
				if (token && user) {
					dispatch(login(JSON.parse(user)));
				} else {
					dispatch(logout());
				}
			}
		}
	}, [STATE.initialization]);

	const routesFilter = (data: IRoute[]) => {
		let routes = data;
		if (STATE.authentication) {
			routes = routes.filter(route => route.auth);
		} else {
			routes = routes.filter(route => !route.auth);
		}
		return routes.map((route, idx) => {
			if (route.redirect) {
				return <Route key={idx} path="*" element={<Navigate to={route.path} replace />} />;
			} else if (route.element) {
				return <Route key={idx} path={route.path} element={route.element} />;
			}
			return null;
		});
	};

	if (!STATE.initialization) {
		return (
			<Routes>
				<Route path={'/'} element={<Layout authentication={STATE.authentication} />}>
					{routesFilter(routesArr)}
				</Route>
			</Routes>
		);
	} else return null;
};

export default Router;
