import { useEffect, FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useToast } from '@chakra-ui/react';
import { toastRemove } from '../../store';
import { IState } from '../../types';

const Toast: FC = () => {
	const dispatch = useDispatch();
	const toast = useToast();
	const STATE = useSelector((state: IState) => state);
	const toastState = STATE.toast;

	useEffect(() => {
		if (toastState) {
			toast({
				title: toastState.title ? toastState.title : toastState.status === 'error' ? 'Error' : '',
				description: toastState.text,
				position: 'top-right',
				status: toastState.status,
				duration: 3000,
				isClosable: true,
				onCloseComplete: () => dispatch(toastRemove()),
			});
		}
	}, [toastState]);

	return null;
};

export default Toast;
