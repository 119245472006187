import { useEffect, useState, FC } from 'react';
import { useSelector } from 'react-redux';
import { Heading, Box } from '@chakra-ui/react';
import PageHeading from '../../components/PageHeading';
import AnimationFramer from '../../components/AnimationFramer';
import AlbumsList from '../../components/AlbumsList';
import { IState, IAlbum, IArtist } from '../../types';

const Albums: FC = () => {
	const STATE = useSelector((state: IState) => state);
	const [load, setLoad] = useState(true);

	const { artists, albums } = STATE.data;

	const [albumList, setAlbumList] = useState<IAlbum[]>([]);

	useEffect(() => {
		if (artists && albums) {
			dataLoad();
		}
	}, [artists, albums]);

	const dataLoad = async () => {
		// For server
		if (artists && albums) {
			let albumsArr: IAlbum[] = [];
			artists.forEach((artist: IArtist) => {
				const currentAlbums: IAlbum[] = albums.filter(e => e.artist_IDs[0] === artist.id).sort((a: IAlbum, b: IAlbum) => parseInt(b.year) - parseInt(a.year));
				albumsArr = albumsArr.concat(currentAlbums);
			});
			setAlbumList(albumsArr.reverse());
		}
		setLoad(false);
	};

	return (
		<Box>
			<PageHeading title={'Albums'} />
			{!load ? (
				<Box>
					<Box {...{ mb: '30px' }}>
						<AnimationFramer>
							<Heading as={'h2'} {...{ fontSize: 32, fontWeight: 'bold' }}>
								Catalog
							</Heading>
						</AnimationFramer>
					</Box>
					<AlbumsList list={albumList} withArtists />
				</Box>
			) : null}
		</Box>
	);
};

export default Albums;
