import { memo, FC } from 'react';
import { Heading, Box } from '@chakra-ui/react';
import AnimationFramer from '../AnimationFramer';

const PageHeading: FC<{ title: string }> = ({ title }) => {
	return (
		<Box {...{ mb: '14px' }}>
			<AnimationFramer>
				<Heading as={'h1'} {...{ fontSize: 18, fontWeight: 'normal' }}>
					{title}
				</Heading>
			</AnimationFramer>
		</Box>
	);
};

export default memo(PageHeading);
