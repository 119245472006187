import { useState, useEffect, FC } from 'react';
import { useLocation, NavLink } from 'react-router-dom';
import { useColorModeValue, Box, Flex, Text, Icon } from '@chakra-ui/react';
import { HouseDoorFill, PeopleFill, VinylFill, MusicPlayerFill } from 'react-bootstrap-icons';
import { ILink } from '../../types';

const LinkItems: Array<ILink> = [
	{ name: 'Home', icon: HouseDoorFill, path: '/', main: true },
	{ name: 'Albums', icon: VinylFill, path: '/albums' },
	{ name: 'Artists', icon: PeopleFill, path: '/artists' },
	{ name: 'Library', icon: MusicPlayerFill, path: '/library' },
];

const Aside: FC = () => {
	const { pathname } = useLocation();
	const [currentPath, setCurrentPath] = useState('');
	const bg = useColorModeValue('rgba(30, 19, 74, 0.9)', 'rgba(20, 28, 65, 0.9)');
	const color = useColorModeValue('#9994ad', '#8a8d9f');

	useEffect(() => {
		if (pathname) {
			if (pathname.split('/').length > 2) {
				setCurrentPath('/' + pathname.split('/')[1]);
			} else {
				setCurrentPath(pathname);
			}
		}
	}, [pathname]);

	return (
		<Box {...{ position: 'fixed', zIndex: 200, bottom: '-1px', left: 0, right: 0, h: '80px', px: '30px', pt: '10px', bg: bg, backdropFilter: 'blur(5px)', borderY: '1px solid rgba(255, 255, 255, 0.1)' }}>
			<Flex {...{ justifyContent: 'space-between', alignItems: 'center' }}>
				{LinkItems.map((item, inx) => {
					const isActive = currentPath === item.path;
					return (
						<Box key={inx}>
							<NavLink to={item.path} end={item.main}>
								<Flex {...{ flexDirection: 'column', justifyContent: 'center', alignItems: 'center', cursor: 'pointer', color: isActive ? '#ffffff' : color }}>
									<Icon as={item.icon} {...{ fontSize: 22 }} />
									<Text {...{ fontSize: 13, fontWeight: 'medium' }}>{item.name}</Text>
								</Flex>
							</NavLink>
						</Box>
					);
				})}
			</Flex>
		</Box>
	);
};

export default Aside;
