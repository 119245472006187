import { useState, useEffect, FC } from 'react';
import { Outlet } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Flex, useColorModeValue } from '@chakra-ui/react';
import { toastSet, infoSet } from '../../store';
import { useImageSetter } from '../../hooks';
import Api from '../../api';
import { IState } from '../../types';
import Toast from '../Toast';
import Header from '../Header';
import Player from '../Player';
import Aside from '../Aside/';

const Layout: FC<{ authentication: IState['authentication'] }> = ({ authentication }) => {
	const dispatch = useDispatch();
	const [loadImages] = useImageSetter();
	const STATE = useSelector((state: IState) => state);
	const { isOnline, playlistShow, playlistID } = STATE;
	const [load, setLoad] = useState(true);

	const bg = useColorModeValue('#ffffff', '#0D1539');

	useEffect(() => {
		if (authentication) {
			if (isOnline) {
				Api.getInfo()
					.then(res => {
						const data = res.data;
						if (data.status === 'success') {
							localStorage.setItem('info', JSON.stringify(data.result));
							dispatch(infoSet(data.result));
						} else {
							dispatch(toastSet({ status: 'error', text: 'Error from server' }));
						}
						setLoad(false);
					})
					.catch(error => {
						dispatch(toastSet({ status: 'error', text: error.response && error.response.data && error.response.data.message ? error.response.data.message : error.message }));
						setLoad(false);
					});
			} else {
				console.log('OFF line');
				const localInfo = localStorage.getItem('info');
				if (localInfo) {
					const info = JSON.parse(localInfo);
					dispatch(infoSet(info));
					setLoad(false);
				}
			}
		} else {
			setLoad(false);
		}
	}, [authentication]);

	if (load) {
		return <span>Load from server...</span>;
	} else {
		return (
			<Box>
				<Box>
					{authentication ? (
						<Flex {...{ overflow: playlistShow ? 'hidden' : 'auto', h: playlistShow ? '100vh' : 'auto' }}>
							<Aside />
							<Box
								{...{
									bg: bg,
									w: '100vw',
									maxWidth: '100vw',
									minHeight: 'calc(100vh - 60px)',
									px: '20px',
									py: '10px',
									mb: '60px',
								}}
							>
								<Header />
								<Box {...{ mt: '20px', mb: playlistID ? '116px' : 0 }}>
									<Outlet />
								</Box>
							</Box>
							<Player />
						</Flex>
					) : (
						<Outlet />
					)}
					<Toast />
				</Box>
			</Box>
		);
	}
};

export default Layout;
