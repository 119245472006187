import { FC, MouseEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Flex, Box, SimpleGrid, Text, useColorModeValue } from '@chakra-ui/react';
import ButtonPlay from '../Buttons/Play';
import ImageLoader from '../ImageLoader';
import AnimationFramer from '../AnimationFramer';
import { playToggle, playlistSet } from '../../store';
import { IState, IAlbum, IArtist } from '../../types';

const AlbumsList: FC<{ list: IAlbum[]; withArtists?: boolean }> = ({ list, withArtists }) => {
	const dispatch = useDispatch();
	const STATE = useSelector((state: IState) => state);
	const { playlistID, playing } = STATE;
	const { artists } = STATE.data;

	const artistFontColor = useColorModeValue('#A8A9AE', '#626784');
	const albumHoverBg = useColorModeValue('rgba(255, 255, 255, 0.3)', 'rgba(0, 0, 0, 0.3)');

	const setActiveAlbum = (e: MouseEvent<HTMLDivElement>, id: IAlbum['id']) => {
		e.stopPropagation();
		if (playlistID === id) {
			dispatch(playToggle());
		} else {
			const curentAlbum: IAlbum | undefined = list.find(el => el.id === id);
			if (curentAlbum) {
				dispatch(playlistSet({ playlist: curentAlbum.track_IDs, playlistID: id }));
			}
		}
	};

	const getArtists = (IDs: IArtist['id'][]) => {
		const artistInfoArr: IArtist[] = [];
		IDs.forEach(id => {
			const artistInfo: IArtist | undefined = artists.find(e => e.id === id);
			if (artistInfo) {
				artistInfoArr.push(artistInfo);
			}
		});
		return artistInfoArr.map(el => {
			return (
				<Link key={el.id} to={`/artists/${el.id}`}>
					<Text variant={'link'}>{el.name}</Text>
				</Link>
			);
		});
	};

	return (
		<SimpleGrid {...{ columns: { base: 2, lg: 3 }, templateColumns: { base: 'repeat(2, calc(50vw - 30px))', lg: 'repeat(3, calc(33vw - 30px))' }, columnGap: { base: '20px', lg: '25px' }, rowGap: { base: '30px', lg: '40px' } }}>
			{list.map(el => {
				const isActive = playing && playlistID === el.id;
				return (
					<AnimationFramer key={el.id}>
						<Flex {...{ flexDirection: 'column', h: '100%', textAlign: 'center' }}>
							<Box {...{ mb: '10px', w: '100%', h: '100%', position: 'relative', borderRadius: '8px', boxShadow: '0 5px 15px 0 rgba(0, 0, 0, 0.15)', overflow: 'hidden' }}>
								<ImageLoader imageID={el.image_ID} {...{ objectFit: 'cover', w: '100%', h: { base: 'calc(50vw - 30px)', lg: 'calc(33vw - 30px)' } }} />
								<Flex
									onClick={e => setActiveAlbum(e, el.id)}
									{...{ opacity: isActive ? 1 : 0, cursor: 'pointer', transition: 'all 0.2s ease-out', position: 'absolute', zIndex: 1, top: '0', bottom: '0', left: '0', right: '0', justifyContent: 'center', alignItems: 'center', _hover: { opacity: 1, bg: albumHoverBg } }}
								>
									<ButtonPlay isActive={isActive} />
								</Flex>
							</Box>

							{withArtists ? <Box {...{ mb: { base: 0, lg: '6px' }, fontSize: 15, fontWeight: 'medium', color: artistFontColor }}>{getArtists(el.artist_IDs)}</Box> : null}
							<Box {...{ fontSize: 14, fontWeight: 'medium', flex: 1 }}>
								<Link to={`/albums/${el.id}`}>
									<Text variant={'link'}>{el.name}</Text>
								</Link>
							</Box>
						</Flex>
					</AnimationFramer>
				);
			})}
		</SimpleGrid>
	);
};

export default AlbumsList;
