import { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useColorModeValue, Heading, Box, Text, Flex, Button, Icon } from '@chakra-ui/react';
import { PlayCircleFill, PauseCircleFill, Star, StarFill } from 'react-bootstrap-icons';
import ImageLoader from '../../components/ImageLoader';
import AnimationFramer from '../../components/AnimationFramer';
import ButtonDownload from '../../components/Buttons/Download';
import { timeFormat } from '../../tools';
import { login, playToggle, playlistSet, toastSet } from '../../store';
import { IState, IAlbum, IArtist } from '../../types';
import { useWindowSize } from '../../hooks';
import Api from '../../api';

const AlbumsSingle = () => {
	const dispatch = useDispatch();
	const STATE = useSelector((state: IState) => state);
	const { isOnline, activeTrack, playlistID, playing } = STATE;
	const { albums, artists, tracks } = STATE.data;
	const { favorite_albums } = STATE.user;

	const { id } = useParams();
	const [width] = useWindowSize();
	const [load, setLoad] = useState(true);

	const [currentAlbum, setCurrentAlbum] = useState<IAlbum | false>(false);
	const [isFavirite, setIsFavorite] = useState(false);

	const iconColor = useColorModeValue('#5D528C', '#ffffff');
	const iconColorBg = useColorModeValue('#ffffff', '#141C41');
	const iconActiveColorBg = useColorModeValue('#5D528C', '#192452');
	const playlistActiveItemBg = useColorModeValue('#5D528C', '#192452');
	const fontColor = useColorModeValue('#727377', '#CCD1E5');
	const bg = useColorModeValue('rgba(255, 255, 255, 0.9)', 'rgba(13, 21, 57, 0.9)');

	useEffect(() => {
		if (albums && id) {
			dataLoad();
		}
	}, [albums, id]);

	const dataLoad = async () => {
		const albumInfo: IAlbum | undefined = albums.find(e => e.id === id);
		if (albumInfo) {
			setCurrentAlbum(albumInfo);
		}
		setLoad(false);
	};

	useEffect(() => {
		if (favorite_albums && favorite_albums.length) {
			const hasFavorite = !!~favorite_albums.findIndex(e => e === id);
			setIsFavorite(hasFavorite);
		}
	}, [favorite_albums]);

	const getArtists = (IDs: IArtist['id'][]) => {
		const artistInfoArr: IArtist[] = [];
		IDs.forEach((id: IArtist['id']) => {
			const artistInfo: IArtist | undefined = artists.find(e => e.id === id);
			if (artistInfo) {
				artistInfoArr.push(artistInfo);
			}
		});
		return artistInfoArr.map((el: IArtist) => {
			return (
				<Link key={el.id} to={`/artists/${el.id}`}>
					<Text variant={'link'}>{el.name}</Text>
				</Link>
			);
		});
	};

	const handleToggleFavorite = () => {
		if (isOnline && id) {
			setIsFavorite(!isFavirite);
			Api.setFavorite({ id, type: 'albums' })
				.then(res => {
					const data = res.data;
					if (data.status === 'success') {
						const { user } = data.result;
						dispatch(login(user));
					} else {
						dispatch(toastSet({ status: 'error', text: 'Error' }));
					}
				})
				.catch(error => {
					dispatch(toastSet({ status: 'error', text: error.response && error.response.data && error.response.data.message ? error.response.data.message : error.message }));
				});
		} else {
			dispatch(toastSet({ status: 'error', text: 'You offline' }));
		}
	};

	const setAlbumOnPlaylist = (trackID = '') => {
		if ((trackID && activeTrack === trackID) || (!trackID && id === playlistID)) {
			dispatch(playToggle());
		} else if (id === playlistID && activeTrack !== trackID) {
			dispatch(playlistSet({ activeTrack: trackID }));
		} else {
			if (currentAlbum) {
				trackID = trackID ? trackID : currentAlbum.track_IDs[0];
				dispatch(playlistSet({ playlist: currentAlbum.track_IDs, playlistID: id, activeTrack: trackID }));
			}
		}
	};

	return (
		<Box>
			{!load && currentAlbum ? (
				<Box>
					<AnimationFramer>
						<Flex {...{ gap: { base: '20px', lg: '50px' }, mb: { base: '10px', lg: '30px' }, flexDirection: { base: 'column', lg: 'row' }, alignItems: { base: 'center', lg: 'flex-end' } }}>
							<Box {...{ position: 'relative' }}>
								<ImageLoader imageID={currentAlbum.image_ID} {...{ h: '300px', w: '300px', borderRadius: '8px', objectFit: 'cover', objectPosition: 'center', boxShadow: '0 5px 15px 0 rgba(0, 0, 0, 0.15)' }} />
								<Flex {...{ display: { base: 'flex', lg: 'none' }, position: 'absolute', gap: '10px', justifyContent: 'end', alignItems: 'center', left: 0, right: 0, bottom: 0, py: '20px', px: '20px' }}>
									<Button onClick={() => setAlbumOnPlaylist()} {...{ p: 0, color: iconColor, backdropFilter: 'blur(5px)', borderRadius: '50%', bg: bg, minWidth: '15px', width: '50px', height: '50px', opacity: 0.9, _hover: { opacity: 1 }, _focus: {}, _active: {} }}>
										<Icon as={playlistID === id && playing ? PauseCircleFill : PlayCircleFill} {...{ fontSize: 40 }} />
									</Button>
									<Button onClick={() => handleToggleFavorite()} {...{ p: 0, color: iconColor, backdropFilter: 'blur(5px)', borderRadius: '50%', bg: bg, minWidth: '15px', width: '40px', height: '40px', opacity: 0.9, _hover: { opacity: 1 }, _focus: {}, _active: {} }}>
										<Icon as={isFavirite ? StarFill : Star} {...{ fontSize: 26, mt: '-2px' }} />
									</Button>
								</Flex>
							</Box>
							<Flex {...{ flexDirection: 'column', justifyContent: 'end', w: { base: '100%', lg: 'auto' } }}>
								<Box {...{ display: { base: 'none', lg: 'block' }, textAlign: 'left' }}>
									<Heading as={'h2'} {...{ fontSize: 18, fontWeight: 'normal' }}>
										Album
									</Heading>
								</Box>
								<Box {...{ mb: { base: 0, lg: '10px' }, textAlign: { base: 'center', lg: 'left' } }}>
									<Heading as={'h1'} {...{ fontSize: { base: 30, lg: 40 }, fontWeight: 'bold' }}>
										{currentAlbum.name}
									</Heading>
								</Box>
								<Flex {...{ gap: '15px', mb: { base: 0, lg: '20px' }, justifyContent: { base: 'center', lg: 'flex-start' } }}>
									<Box>{getArtists(currentAlbum.artist_IDs)}</Box>
									<Box>|</Box>
									<Box>{currentAlbum.year}</Box>
									<Box>|</Box>
									<Box>{currentAlbum.track_IDs.length} Tracks</Box>
								</Flex>
								<Flex {...{ display: { base: 'none', lg: 'flex' }, gap: '20px' }}>
									<Button onClick={() => setAlbumOnPlaylist()} {...{ p: 0, color: iconColor, bg: 'transparent', minWidth: '15px', width: 'auto', height: 'auto', opacity: 0.9, _hover: { opacity: 1 }, _focus: {}, _active: {} }}>
										<Icon as={playlistID === id && playing ? PauseCircleFill : PlayCircleFill} {...{ fontSize: 50 }} />
									</Button>
									<Button onClick={() => handleToggleFavorite()} {...{ p: 0, color: iconColor, bg: 'transparent', minWidth: '15px', width: 'auto', height: 'auto', opacity: 0.9, _hover: { opacity: 1 }, _focus: {}, _active: {} }}>
										<Icon as={isFavirite ? StarFill : Star} {...{ fontSize: 30 }} />
									</Button>
								</Flex>
							</Flex>
						</Flex>
					</AnimationFramer>
					<Box {...{ maxW: '1500px' }}>
						{currentAlbum.track_IDs.length ? (
							<Box>
								<AnimationFramer>
									<Flex {...{ display: { base: 'none', lg: 'flex' }, w: '100%', h: '70px', gap: '10px', alignItems: 'center', color: fontColor }}>
										<Box {...{ fontWeight: '400', w: '70px', textAlign: 'center', p: '8px' }}>#</Box>
										<Box {...{ w: '70px' }} />
										<Box {...{ flex: '1', fontWeight: '400' }}>Title</Box>
										<Box {...{ flex: '1', fontWeight: '400' }}>Album</Box>
										<Box {...{ flex: '1', fontWeight: '400' }}>Artists</Box>
										<Box {...{ w: '40px' }} />
										<Box {...{ w: '70px', fontWeight: '400', textAlign: 'right' }}>Time</Box>
										<Box {...{ w: '15px' }} />
									</Flex>
								</AnimationFramer>
								{currentAlbum.track_IDs.map((e, i) => {
									const isActive = activeTrack === e;
									const trackInfo = tracks.find(f => f.id === e);
									if (trackInfo) {
										const artistsArr: IArtist[] = [];
										trackInfo.artist_IDs.forEach(id => {
											const artistInfo: IArtist | undefined = artists.find(f => f.id === id);
											if (artistInfo) artistsArr.push(artistInfo);
										});
										return (
											<AnimationFramer key={e}>
												<Flex
													onClick={() => (width <= 992 ? setAlbumOnPlaylist(e) : null)}
													{...{
														width: '100%',
														h: { base: '50px', lg: '70px' },
														gap: '10px',
														alignItems: 'center',
														fontSize: 15,
														color: isActive ? '#ffffff' : fontColor,
														background: isActive ? playlistActiveItemBg : 'transparent',
														borderRadius: isActive ? '4px' : '0',
														boxShadow: isActive ? '0 3px 4px 0 rgba(0, 0, 0, 0.05)' : 'none',
													}}
												>
													<Flex {...{ justifyContent: 'center', alignItems: 'center', w: { base: '30px', lg: '70px' }, h: { base: '50px', lg: '70px' }, position: 'relative' }}>
														<Text>{i + 1}</Text>
														<Button
															onClick={() => setAlbumOnPlaylist(e)}
															{...{
																display: { base: 'none', lg: 'inline-flex' },
																position: 'absolute',
																borderRadius: '50%',
																p: 0,
																minWidth: '15px',
																width: 'auto',
																height: 'auto',
																color: isActive ? '#ffffff' : iconColor,
																bg: isActive ? iconActiveColorBg : iconColorBg,
																opacity: 0,
																_hover: { opacity: 1 },
																_focus: { opacity: 1 },
															}}
														>
															<Icon as={isActive && playing ? PauseCircleFill : PlayCircleFill} {...{ fontSize: 30 }} />
														</Button>
													</Flex>
													<Box {...{ w: '70px', display: { base: 'none', lg: 'block' } }}>
														<ImageLoader imageID={trackInfo.image_ID} {...{ borderRadius: '4px', h: '50px', w: '50px', boxShadow: '0 2px 7px 0 rgba(0, 0, 0, 0.50)' }} />
													</Box>
													<Box {...{ flex: '1' }}>{trackInfo.name}</Box>
													<Box {...{ flex: '1', display: { base: 'none', lg: 'block' } }}>{currentAlbum.name}</Box>
													<Box {...{ flex: '1', display: { base: 'none', lg: 'block' } }}>
														<Flex {...{ gap: '5px' }}>
															{artistsArr.map((a, ai) => {
																return (
																	<Link key={ai} to={`/artists/${a.id}`}>
																		<Text variant={'link'}>{a.name}</Text>
																	</Link>
																);
															})}
														</Flex>
													</Box>
													<Box {...{ w: '40px' }}>
														<ButtonDownload ID={e} type={'track'} {...{ fontSize: 24 }} />
													</Box>
													<Box {...{ w: { base: '40px', lg: '70px' }, textAlign: 'right' }}>{timeFormat(trackInfo.duration)}</Box>
													<Box {...{ w: { base: '10px', lg: '15px' } }}>{isActive ? <Box {...{ h: '28px', w: '3px', bg: '#ffffff', borderRadius: '5px', boxShadow: '0 2px 7px 0 rgba(0, 0, 0, 0.50)' }} /> : null}</Box>
												</Flex>
											</AnimationFramer>
										);
									} else return null;
								})}
							</Box>
						) : null}
					</Box>
				</Box>
			) : null}
		</Box>
	);
};

export default AlbumsSingle;
