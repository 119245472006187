import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useColorMode, useColorModeValue, Flex, IconButton, Icon } from '@chakra-ui/react';
import { ChevronLeftIcon, Search2Icon } from '@chakra-ui/icons';
import { MoonFill, SunFill, PersonFill } from 'react-bootstrap-icons';

const Header = () => {
	const navigate = useNavigate();
	const { colorMode, toggleColorMode } = useColorMode();
	const { pathname } = useLocation();
	const [history, setHistory] = useState<string[]>([]);
	const [prev, setPrev] = useState(false);

	const iconColor = useColorModeValue('#5d528c', '#ffffff');

	useEffect(() => {
		const metaThemeColor = document.querySelector('meta[name=theme-color]');
		if (metaThemeColor) {
			metaThemeColor.setAttribute('content', colorMode === 'dark' ? '#0D1539' : '#ffffff');
		}
	}, [colorMode]);

	useEffect(() => {
		if (!history.length) {
			if (pathname) {
				setHistory([pathname]);
			}
		} else {
			if (prev) {
				setPrev(false);
			} else {
				const historyArr = history.concat([pathname]);
				setHistory(historyArr);
			}
		}
	}, [pathname]);

	useEffect(() => {
		if (prev) {
			const historyArr = history.slice(0, -1);
			setHistory(historyArr);
			navigate(historyArr[historyArr.length - 1]);
		}
	}, [prev]);

	return (
		<Flex
			{...{
				my: '5px',
				gap: '60px',
				alignItems: 'center',
				justifyContent: 'space-between',
			}}
		>
			<Flex {...{ gap: '12px', ml: '-7px' }}>
				<IconButton variant={'navArrow'} isDisabled={history.length < 2} aria-label="prev" icon={<ChevronLeftIcon />} onClick={() => setPrev(true)} />
			</Flex>
			<Flex {...{ gap: '20px', alignItems: 'center' }}>
				<Link to={'/search'}>
					<Flex {...{ alignItems: 'center' }}>
						<Search2Icon {...{ fontSize: 18, color: iconColor }} />
					</Flex>
				</Link>
				<Link to={'/account'}>
					<Flex {...{ alignItems: 'center' }}>
						<Icon as={PersonFill} {...{ fontSize: 24, color: iconColor }} />
					</Flex>
				</Link>
				<Flex {...{ alignItems: 'center' }}>
					<Icon onClick={toggleColorMode} as={colorMode === 'dark' ? MoonFill : SunFill} {...{ cursor: 'pointer', fontSize: 18, color: iconColor }} />
				</Flex>
			</Flex>
		</Flex>
	);
};

export default Header;
